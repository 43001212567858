import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ProjectSchedulingModalV2 from '../../../../components/scheduling/project-scheduling-v2/project_scheduling_modal';
import TaskLocationModal, {taskLocationModalQuery} from '../../../../containers/modal/task_location_modal';
import NewProjectModalV2, {newProjectModalV2Query} from '../../../../containers/modal/new_project_modal_v2';
import AddProjectGroupModal, {addProjectGroupModalQuery} from '../../../../containers/modal/add_project_group_modal';
import SetToRunningReminderModal, {
	setToRunningReminderModalQuery,
} from '../../../../containers/modal/set_to_running_reminder_modal';
import FileLocationModal from '../../../../containers/modal/file_location_modal';
import UserInputModal from '../../../../containers/modal/user_input_modal';
import AddPeriodsModal from '../../../../containers/modal/add_periods_modal';
import ProjectBudgetChangeModalOld from '../../../../containers/modal/project_budget_change_modal';
import ProjectBudgetChangeModal from '../../../../containers/modal/ProjectBudgetChangeModal';
import NotPermittedProjectBudgetChangeModal from '../../../../containers/modal/not_permitted_project_budget_change_modal';
import SelectModal from '../../../../containers/modal/select_modal';
import SelectModalV2 from '../../../../containers/modal/select_modal_v2';
import BulkTaskUpdateModal from '../../../../containers/modal/bulk_task_update_modal';
import AddTeamModal from '../../../../containers/modal/add_team_modal';
import MutationErrorModal from '../../../../components/mutation_error_modal';
import AddClientUserModal, {addClientUserModalQuery} from '../../../../containers/modal/add_client_user_modal';
import AddTeamMemberModal, {addTeamMemberModalQuery} from '../../../../containers/modal/add_team_member_modal';
import ExpenseItemModal, {expenseItemModalQuery} from '../../../../containers/modal/expense_item_modal/expense_item_modal';
import RateCardModal, {rateCardModalQuery} from '../../../../containers/modal/rate_card_modal/RateCardModal';
import TaskRecurrenceModal from '../../../../containers/modal/task_recurrence_modal';
import AddClientModal, {addClientModalQuery} from '../../../../containers/modal/add_client_modal';
import GenericModal from '../../../../containers/modal/generic_modal';
import ImportHolidayCalendarEntriesModal from '../../../../containers/modal/import_holiday_calendar_entries_modal';
import BoundaryErrorModal from '../../../../components/boundary_error_modal';
import FileModal from '../../../../containers/modal/file_modal';
import AllocateTeamMembersModalV2, {
	allocateTeamMembersModalV2Query,
} from '../../../../containers/modal/allocate_team_members_modal_v2';
import {ProjectAutomateModal} from '../../../../containers/modal/project-automate/project_automate_modal';
import ExportInvoiceQuickbooksModal from '../../../../containers/modal/export_invoice_quickbooks_modal';
import CanvasCreateModal from '../../../../components/canvas-scheduling/modals/create_modal';
import CanvasCreateNewModal from '../../../../components/canvas-scheduling/modals/create_new_modal';
import CanvasUtilizationModal from '../../../../components/canvas-scheduling/modals/utilization_modal';
import UtilizationModalV2 from '../../../../components/canvas-scheduling/modals/UtilizationModalV2';
import {StandaloneUtilizationModal} from '../../../../components/canvas-scheduling/modals/standalone_utilization_modal';
import AutoSchedulingModal, {
	autoSchedulingModalQuery,
} from '../../../../components/canvas-scheduling/modals/auto_scheduling_modal';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import TimerModalV3, {timerModalV3Query} from '../../../../containers/modal/timer_modal_v3';
import WarningModal from '../../../../containers/modal/warning_modal';
import ProfileModal from '../../../admin-tab/permissions-page/profile_modal';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import CopyTimeModal from '../../../../containers/new-ui/time_overview/copy_time_modal';
import {hideLoader, showLoader} from '../../../../containers/global_loader';
import SeatManagementModal from '../../../../containers/modal/seat_management_modal';
import GenericDeletionWarningModal from '../../../../containers/modal/generic_deletion_warning_modal';
import TaskDeletionWarningModal from '../../../../containers/modal/task_deletion_warning_modal';
import PersonDeactivateWarningModal from '../../../../containers/modal/person_deactivate_warning_modal';
import HarvestTaskSelectModal from '../../../../containers/modal/harvest_task_select_modal';
import Unit4ActivitySelectModal from '../../../../containers/modal/unit4_activity_select_modal';
import CostPeriodsModal, {costPeriodsModalQuery} from '../../../../containers/modal/cost_periods_modal';
import CreateJiraIssueModal, {createJiraIssueModalQuery} from '../../../../containers/modal/create_jira_issue_modal';
import CreateJiraMilestoneModal, {
	createJiraMilestoneModalQuery,
} from '../../../../containers/modal/create_jira_milestone_modal';
import CreateAdoWorkitemModal from '../../../../containers/modal/create_ado_workitem_modal';
import AddProjectDatesModal from '../../../../containers/modal/add_project_dates_modal';
import FixUnassignedTasksModal from '../../../../containers/modal/fix_unassigned_tasks_modal';
import FixUnestimatedTasksModal from '../../../../containers/modal/fix_unestimated_tasks_modal';
import CostRateModal from '../../../../containers/modal/change_cost_rate_modal';
import ChangeFixedPriceModal from '../../../../containers/modal/change_fixed_price_modal';
import UpdateFixedPriceModal from '../../../../containers/modal/update_fixed_price_modal';
import ConfirmUpdateFixedPriceModal from '../../../../containers/modal/confirm_update_fixed_price_modal';
import BudgetFixedPriceModal from '../../../../containers/modal/budget_fixed_price_modal';
import AddEmailModal from '../../../admin-tab/resources-page/add_email_modal';
import WarningRadioButtonModal from '../../../../containers/modal/warning_radio_button_modal';
import RetainerTimeEntryConflictModal from '../../../../containers/modal/retainer_time_entry_conflict_modal';
import TimeRegsOnProjectModal from '../../../../containers/modal/time_reg_on_project_modal';
import TimeRegsOutsideProjectPeriod from '../../../../containers/modal/time_regs_outside_project_period_modal';
import TaskOutsideProjectPeriodModal from '../../../../containers/modal/task_outside_project_period_modal';
import ExpensesOutsideProjectPeriod from '../../../../containers/modal/expenses_outside_project_period_modal';
import PhaseLocationModal, {phaseLocationModalQuery} from '../../../../containers/modal/phase_location_modal';
import AddRateCardModal, {addRateCardModalQuery} from '../../../../containers/modal/add_rate_card_modal';
import TaskModalWrapper from '../../../../containers/task_modal_wrapper';
import SyncJiraProjectModal, {syncJiraProjectModalQuery} from '../../../../containers/modal/sync_jira_project_modal';
import LinkADOProjectModal from '../../../../containers/modal/link_ado_project_modal';
import LinkUnit4ProjectModal, {linkUnit4ProjectModalQuery} from '../../../../containers/modal/link_unit4_project_modal';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import ProjectPhaseCapacityModal from '../../../../components/canvas-scheduling/projects-scheduling/project_phase_capacity_modal';
import ChangeAllocationTypeModal from '../../../admin-tab/company-details-page/change_allocation_type_modal';
import Unit4SyncUsersModal, {unit4SyncUsersModalQuery} from '../../../../containers/modal/unit4_sync_users_modal';
import JiraSyncUsersModal, {jiraSyncUsersModalQuery} from '../../../../containers/modal/jira_sync_users_modal';
import BambooHRImportUsersModal, {bambooHRImportUsersModalQuery} from '../../../../containers/modal/BambooHRImportUsersModal';
import BambooHRImportTimeOffTypesModal from '../../../../containers/modal/BambooHRImportTimeOffTypesModal';
import AdoImportUsersModal, {adoImportUsersModalQuery} from '../../../../containers/modal/azure_devops/AdoImportUsersModal';
import IntegrationsInfoModal from '../../../../containers/modal/integrations/IntegrationsInfoModal';
import AdoLinkUserModal, {adoLinkUserModalQuery} from '../../../../containers/modal/azure_devops/AdoLinkUserModal';
import XeroSyncClientsModal from '../../../../containers/modal/xero_sync_clients_modal';
import XeroInaccessibleModal from '../../../../containers/modal/XeroInaccessibleModal';
import QuickbooksImportCustomersModal from '../../../../containers/modal/QuickbooksImportCustomersModal';
import EconomicImportCustomersModal from '../../../../containers/modal/EconomicImportCustomersModal';
import SageIntacctImportCustomersModal from '../../../../containers/modal/sage_intacct/SageIntacctImportCustomersModal';
import ConfirmPasswordModal from '../../../../containers/modal/confirm_password_modal';
import CreateInvoiceModalV2 from '../../../../containers/modal/create_invoice_modal_v2/create_invoice_modal_v2';
import StandaloneCreateInvoiceModalV2, {
	standaloneCreateInvoiceModalV2Query,
} from '../../../../containers/modal/create_invoice_modal_v2/standalone_create_invoice_modal_v2';
import SaveDemoEnvironmentModal from '../../../../containers/modal/save_demo_environment_modal';
import UpdateSprintPerformanceModal from '../../../../containers/modal/UpdateSprintPerformanceModal';
import WarningModalLockedTimeRegs from '../../../../containers/modal/warning_modal_locked_time_regs';
import WarningRoleUsedTimeRegModal from '../../../../containers/modal/warning_modal_role_used_time_regs';
import XeroExportModal, {xeroExportModalQuery} from '../../../../containers/modal/export_invoice_modals/xero_export_modal';
import QuickbooksExportModal, {
	quickbooksExportModalQuery,
} from '../../../../containers/modal/export_invoice_modals/quickbooks_export_modal';
import EconomicExportModal, {
	economicExportModalQuery,
} from '../../../../containers/modal/export_invoice_modals/economic_export_modal';
import SageIntacctExportModal, {
	sageIntacctExportModalQuery,
} from '../../../../containers/modal/export_invoice_modals/sageIntacct_export_modal';
import ApproveAndLockModal from '../../../../containers/modal/baseline-approve-and-lock/ApproveAndLockModal';
import CloseSprintModal from '../../../../containers/modal/CloseSprintModal';
import JiraEditEpicsModal, {jiraEditEpicsModalQuery} from '../../../../containers/modal/jira_sync/JiraEditEpicsModal';
import SubmitTimeApprovalModal from '../../../../containers/modal/time_approval/SubmitTimeApprovalModal';
import ActivateTimeApprovalModal, {
	ActivateTimeApprovalModalQuery,
} from '../../../../containers/modal/time_approval/ActivateTimeApprovalModal';
import HTMLTextButtonsModal from '../../../../containers/modal/HTMLTextButtonsModal';
import TimeLockModal, {TimeLockModalQuery} from '../../../../components/timesheets/time-lock/TimeLockModal';
import IntegrationIncompatibleModal from '../../../../containers/modal/time_approval/IntegrationIncompatibleModal';
import {RejectSubmissionModal} from '../../../../containers/modal/time_approval/RejectSubmissionModal';
import CloseProjectModal from '../../../../containers/modal/CloseProjectModal';
import NoAvailableSeatsModal from '../../../../containers/modal/NoAvailableSeatsModal';
import UnsubmitTimeApprovalModal from '../../../../containers/modal/time_approval/UnsubmitTimeApprovalModal';
import JiraStartDateModal, {jiraStartDateModalQuery} from '../../../../containers/modal/jira_sync/JiraStartDateModal';
import SalesforceImportClientsModal from '../../../../containers/modal/SalesforceImportClientsModal';
import {TypeToDeleteModal} from '../../../../containers/modal/TypeToDeleteModal';
import ReportedTimeCSVModalWrapper from './time-registration/csv/ReportedTimeCSVModalWrapper';
import HubspotImportClientsModal from '../../../../containers/modal/HubspotImportClientsModal';
import PipedriveImportClientsModal from '../../../../containers/modal/PipedriveImportClientsModal';
import ImportExpenseItemModal, {importExpenseItemModalQuery} from '../../../../containers/modal/import_expense_item_modal';
import ImportSageIntacctExpenseItemModal, {
	importSageIntacctExpenseItemModalQuery,
} from '../../../../containers/modal/import_sage_intacct_expense_item_modal';
import ChangeExpenseCategoryModal, {
	ChangeExpenseCategoryModalQuery,
} from '../../../../components/expense-management/modal/ChangeExpenseCategoryModal';
import ChangeExpenseApprovalModal from '../../../../components/expense-management/modal/ChangeExpenseApprovalModal';
import ChangeExpenseBillableStatusModal from '../../../../components/expense-management/modal/ChangeExpenseBillableStatusModal';
import ChangeExpenseFixedPriceBillingModal from '../../../../components/expense-management/modal/ChangeExpenseFixedPriceBillingModal';
import FixedPriceLockModal from '../../../../components/new-ui/project/project-budget-v3/modal/fixed_price_lock_modal';
import FixedPriceOpenLockModal from '../../../../containers/modal/fixed_price_open_lock_modal';
import {CsvDownloadModal} from '../../../../containers/modal/csv_download_modal/CsvDownloadModal';
import {AdjustBaselineModal} from '../../../../containers/modal/AdjustBaselineModal';
import TaskStatusMicroAssistModal, {
	TaskStatusMicroAssistModalQuery,
} from '../../../../containers/modal/TaskStatusMicroAssistModal';
import BulkTaskUpdateModalWrapper, {
	BulkTaskUpdateModalWrapperQuery,
} from '../../../../containers/modal/BulkTaskUpdateModalWrapper';
import AllocationAssistanceModalRelayWrapper from '../../../../containers/project/project_health/modals/allocation_assistance_modal/AllocationAssistanceModalRelayWrapper';
import PredictedEndDateModalRelayWrapper from '../../../../containers/project/project_health/modals/predicted_end_date_modal/PredictedEndDateModalRelayWrapper';
import FocusModalRelayWrapper from '../../../../containers/project/project_health/modals/focus_modal/FocusModalRelayWrapper';
import PerformanceModalRelayWrapper from '../../../../containers/project/project_health/modals/performance_modal/PerformanceModalRelayWrapper';
import {ProjectStatusModalV2} from '../../../../containers/project/project_health/modals/project_status_modal/ProjectStatusModalV2';
import ConvertOldSubtasksModal, {
	convertOldSubtasksModalQuery,
} from '../../../project-tab/projects/project-settings/modals/ConvertOldSubtasksModal';
import ReportedTimeDateModal from './time-registration/csv/ReportedTimeDateModal';
import CreateSkillCategoryModal, {
	createSkillCategoryModalQuery,
} from '../../../../containers/settings/skills/CreateSkillCategoryModal';
import PersonSkillModal, {personSkillsModalQuery} from '../../../../containers/modal/skills/PersonSkillModal';
import UpdateSkillCategoryModal, {
	updateSkillCategoryModalQuery,
} from '../../../../containers/settings/skills/UpdateSkillCategoryModal';
import CreateSkillModal, {createSkillModalQuery} from '../../../../containers/settings/skills/CreateSkillModal';
import UpdateSkillModal, {updateSkillModalQuery} from '../../../../containers/settings/skills/UpdateSkillModal';
import AddPersonToProjectModal from '../../../../containers/modal/AddPersonToProjectModal';
import AssignSkillsToPersonsModal, {
	assignSkillsToPersonsModalQuery,
} from '../../../../containers/settings/skills/AssignSkillsToPersonsModal';
import MoveMultipleSkillsToCategoryModal, {
	moveMultipleSkillsToCategoryModalQuery,
} from '../../../../containers/settings/skills/MoveMultipleSkillsToCategoryModal';
import ExternalShareReportModal from '../../../reports-tab/shared-report/ExternalShareReportModal';
import ForecastSurplusModalRelayWrapper from '../../../../containers/project/project_health/modals/forecast_surplus_modal/ForecastSurplusModalRelayWrapper';
import CreateLabelModal, {createLabelModalQuery} from '../../../admin-tab/labels-page/CreateLabelModal';
import UpdateLabelModal, {updateLabelModalQuery} from '../../../admin-tab/labels-page/UpdateLabelModal';
import CreateLabelCategoryModal, {createLabelCategoryModalQuery} from '../../../admin-tab/labels-page/CreateLabelCategoryModal';
import UpdateLabelCategoryModal, {updateLabelCategoryModalQuery} from '../../../admin-tab/labels-page/UpdateLabelCategoryModal';
import MoveMultipleLabelsToCategoryModal, {
	moveMultipleLabelsToCategoryModalQuery,
} from '../../../admin-tab/labels-page/MoveMultipleLabelsToCategoryModal';
import MergeLabelsModal, {mergeLabelsModalQuery} from '../../../admin-tab/labels-page/MergeLabelsModal';

import PlaceholderModal, {placeholderModalQuery} from '../../../../containers/modal/placeholder/PlaceholderModal';
import PlaceholderAllocationModal, {
	placeholderAllocationModalQuery,
} from '../../../../containers/modal/placeholder/PlaceholderAllocationModal';
import DeletePlaceholderConfirmationModal from '../../../../containers/modal/placeholder/DeletePlaceholderConfirmationModal';
import {CreateProgramModal} from './program-management/CreateProgramModal';
import ImportProjectToProgramModal, {ImportProjectToProgramModalQuery} from './program-management/ImportProjectToProgramModal';
import DirectApi from '../../../../directApi';
import CustomFieldDefinitionModal, {
	CustomFieldDefinitionModalQuery,
} from '../../../admin-tab/custom-fields-page/CustomFieldDefinitionModal';
import DeletePriorityLevelModal, {
	deletePriorityLevelModalQuery,
} from '../../../admin-tab/project-priority-page/DeletePriorityLevelModal';
import ReplaceWithPersonModal, {
	replaceWithPersonModalQuery,
} from '../../../../containers/modal/placeholder/ReplaceWithPersonModal';
import TransferToPersonModal, {
	transferToPersonModalQuery,
} from '../../../../containers/modal/placeholder/TransferToPersonModal';
import PlaceholderDivideAllocationModal, {
	placeholderDivideAllocationModalQuery,
} from '../../../../containers/modal/placeholder/PlaceholderDivideAllocationModal';
import PlaceholderStaffingUnsavedChanges from '../../../../containers/modal/placeholder/PlaceholderStaffingUnsavedChanges';
import {ProgressRegistrationModal} from '../../../project-tab/projects/project-settings/modals/ProgressRegistrationModal';
import {ForecastThemeProvider} from '../../ForecastThemeProvider';
import DeleteProgramModal from './program-management/DeleteProgramModal';
import RemoveProjectFromProgramModal from './program-management/RemoveProjectFromProgramModal';
import DeleteSkillLevelModal from '../../../../containers/settings/skills/DeleteSkillLevelModal';
import ManageSkillLevelsModal, {
	manageSkillLevelsModalQuery,
} from '../../../../containers/settings/skills/ManageSkillLevelsModal';
import EnableSkillLevelsInfoModal from '../../../../containers/settings/skills/EnableSkillLevelsInfoModal';
import TaskReportCSVModalWrapper from './task/TaskReportCSVModalWrapper';
import ToggleRevenueModal from '../../../admin-tab/setup-page/ToggleRevenueModal';
import ToggleTimesheetsModal from '../../../admin-tab/setup-page/ToggleTimesheetsModal';
import ToggleClientsModal from '../../../admin-tab/setup-page/ToggleClientsModal';
import UpdateContactModal, {updateContactModalQuery} from '../../../admin-tab/company-details-page/UpdateContactModal';
import UpdateRateAndInternalCostLiteModal from '../../../../components/new-ui/project/project-budget-v3/RatesAndInternalCost/UpdateRateAndInternalCostLiteModal';
import AssignToPersonModal, {assignToPersonModalQuery} from '../../../../containers/modal/placeholder/AssignToPersonModal';
import RemoveMemberFromProgramModal from './program-management/RemoveMemberFromProgramModal';
import {ChangeProgramBudgetTypeModal} from './program-management/ChangeProgramBudgetTypeModal';
import {ProgramBudgetTypeChangedModal} from './program-management/ProgramBudgetTypeChangedModal';
import {ProgramAllocationsChangedModal} from './program-management/ProgramAllocationsChangedModal';
import DemoGenerationModal from '../../../admin-tab/company-details-page/demo-data/DemoGenerationModal';
import {ChangeProgramBudgetValueModal} from './program-management/ChangeProgramBudgetValueModal';
import {LeaveAllocationsPageModal} from './program-management/LeaveAllocationsPageModal';
import ChangeCompanyWeekendVisibilityModalContent from '../../../admin-tab/company-details-page/ChangeCompanyWeekendVisibility';
import {ChangePeriodTargetModal} from '../../../project-tab/projects/project-retainer-periods/ChangePeriodTargetModal';
import SubmitTimeOffApprovalModal from '../../../../containers/modal/time_approval/SubmitTimeOffApprovalModal';
import {TypeConfirmModal} from '../../../../containers/modal/TypeConfirmModal';
import RateCardRoleDeactivateWarningModal from '../../../../containers/modal/rate_card_modal/RateCardRoleDeactivateWarningModal';
import RoleDeactivatedWarningModal from '../../../project-tab/projects/project-settings/modals/RoleDeactivatedWarningModal';
import ProgramBudgetErrorMessage from './program-management/ProgramBudgetErrorMessage';
import ChangeCapSettingModal from './program-management/ChangeCapSettingModal';
import {hasTopDownProgramBudgetFeature} from '../../util/ProgramFinancialLogic';
import OwnedProjectsModal, {OwnedProjectsModalQuery} from './projects/owned-projects/OwnedProjectsModal';
import StandaloneEditInvoiceModal, {
	standaloneEditInvoiceModalQuery,
} from '../../../../containers/modal/edit_invoice_modal/standalone_edit_invoice_modal';
import TimeRegistrationModalCreateQueryWrapper from './time-registration/time-registration-modal/query-wrappers/TimeRegistrationModalCreateQueryWrapper';
import TimeRegistrationModalUpdateQueryWrapper from './time-registration/time-registration-modal/query-wrappers/TimeRegistrationModalUpdateQueryWrapper';
import TimeRegistrationModalTimerQueryWrapper from './time-registration/time-registration-modal/query-wrappers/TimeRegistrationModalTimerQueryWrapper';
import BulkInvoiceCreateModalQueryWrapper from './projects/retainer/bulk-period-invoice-modal/BulkInvoiceCreateModalQueryWrapper';
import {ActionCompleteGoToLinkModal} from './navigation/ActionCompleteGoToLinkModal';
import RelinkBaselineScopingDatesModal from './settings/baseline/RelinkBaselineScopingDatesModal';
import SageIntacctImportTeamMembersModal, {
	sageIntacctImportTeamMembersModalQuery,
} from '../../../../containers/modal/sage_intacct/SageIntacctImportTeamMembersModal';
import {TimesheetsCopyLastWeekModal} from '../../../my-work-tab/my-timesheets-page/timesheets-copy-last-week/TimesheetsCopyLastWeekModal';
import ClientPermissionsModal from '../../../admin-tab/clients-page/ClientPermissionsModal';
import SageIntacctImportDepartmentsModal from '../../../../containers/modal/sage_intacct/SageIntacctImportDepartmentsModal';
import SageIntacctExportTimesheetsModal, {
	sageIntacctExportTimesheetsModalQuery,
} from '../../../../containers/modal/sage_intacct/SageIntacctExportTimesheetsModal';
import SageIntacctSyncProjectModal from '../../../../containers/modal/sage_intacct/SageIntacctSyncProjectModal';
import SageIntacctCreateNewProjectModal, {
	sageIntacctCreateNewProjectModalQuery,
} from '../../../../containers/modal/sage_intacct/SageIntacctCreateNewProjectModal';
import SageIntacctExportTasksModal, {
	sageIntacctExportTasksModalQuery,
} from '../../../../containers/modal/sage_intacct/SageIntacctExportTasksModal';
import AddBillToEntityModal, {AddBillToEntityModalQuery} from '../../../admin-tab/finance-page/modals/AddBillToEntityModal';
import EditClientDetailsModal, {EditClientDetailsModalQuery} from '../../../admin-tab/clients-page/EditClientDetailsModal';
import AssociateBillToEntityModal, {
	AssociateBillToEntityModalQuery,
} from '../../../admin-tab/finance-page/modals/AssociateBillToEntityModal';
import EditNotesModal from '../../../admin-tab/clients-page/EditNotesModal';
import AssociateBillToModal, {AssociateBillToModalQuery} from '../../../admin-tab/finance-page/modals/AssociateBillToModal';
import UnassociateBillToModal, {
	UnassociateBillToModalQuery,
} from '../../../admin-tab/finance-page/modals/UnassociateBillToModal';
import RemoveBillToModal, {RemoveBillToModalQuery} from '../../../admin-tab/finance-page/modals/RemoveBillToModal';
import RemoveProjectBillToModal from '../../../admin-tab/finance-page/modals/RemoveProjectBillToModal';
import AddProjectBillToModal, {AddProjectBillToModalQuery} from '../../../admin-tab/finance-page/modals/AddProjectBillToModal';
import AssociateClientToProjectModal, {
	AssociateClientToProjectModalQuery,
} from '../../../admin-tab/finance-page/modals/AssociateClientToProjectModal';
import ExportInvoiceBillingOptionModal, {
	ExportInvoiceBillingOptionModalQuery,
} from '../../../../containers/project/budget/invoicing/modal/ExportInvoiceBillingOptionModal';
import MissingClientBillToWarningModal from '../../../../containers/project/budget/invoicing/modal/MissingClientBillToWarningModal';
import ProjectsBillingDetailsWarningModal from '../../../../containers/project/budget/invoicing/modal/ProjectsBillingDetailsWarningModal';
import DisableIntegrationWarningModal from '../../../admin-tab/finance-page/modals/DisableIntegrationWarningModal';
import {exportInvoiceModalQuery} from '../../../../containers/modal/export_invoice_modal/ExportInvoiceModal.query';
import ExportInvoiceModal from '../../../../containers/modal/export_invoice_modal/ExportInvoiceModal';
import InvoicedBillToWarningModal, {
	InvoicedBillToWarningModalQuery,
} from '../../../admin-tab/finance-page/modals/InvoicedBillToWarningModal';
import RemoveClientBillToModal from '../../../admin-tab/finance-page/modals/RemoveClientBillToModal';
import WarningClientChangeExportedInvoiceBillToMismatchModal from '../../../admin-tab/finance-page/modals/WarningClientChangeExportedInvoiceBillToMismatchModal';
import GoogleOAuthErrorModal from '../../../../containers/settings/settingsCalendar/oauth-button/GoogleOAuthErrorModal';
import ShowHideColumnsModal from '../changeview/show-hide-columns/ShowHideColumnsModal';
import ExpenseItemChangeProjectPhaseModal, {
	ExpenseItemChangeProjectPhaseModalQuery,
} from '../../../../components/expense-management/modal/ExpenseItemChangeProjectPhaseModal';
import ExpenseItemChangePersonModal, {
	ExpenseItemChangePersonModalQuery,
} from '../../../../components/expense-management/modal/ExpenseItemChangePersonModal';
import DeleteExpenseModal from '../../../../components/expense-management/DeleteExpenseModal';
import DeleteExpensesModal from '../../../../components/expense-management/modal/DeleteExpensesModal';
import ChangeExpensesMarkupModal from '../../../../components/expense-management/modal/ChangeMarkupModal';
import ConfirmationModal from './general/ConfirmationModal';
import {registerMutationError} from '../../../../sentry/RegisterError';
import RevertRolloverModal from '../../../../containers/modal/revert_rollover_modal/RevertRolloverModal';
import DeleteRetainerPeriodModal from '../../../../containers/modal/delete_retainer_period_modal/DeleteRetainerPeriodModal';
import RenameReportModal from '../../../reports-tab/reports-page/actions/rename-report/RenameReportModal';
import DeleteReportModal from '../../../reports-tab/reports-page/actions/delete-report/DeleteReportModal';
import ShareReportModal from '../../../reports-tab/reports-page/actions/share-report/ShareReportModal';
import {pathIncludesTask, removeTaskLinkFromUrl} from '../../util/UrlUtil';
import TransferOwnershipModal, {
	TransferOwnershipModalQuery,
} from '../../../reports-tab/reports-page/actions/transfer-ownership/TransferOwnershipModal';
import CreateReportModal, {
	CreateReportModalQuery,
} from '../../../reports-tab/reports-page/actions/create-report/CreateReportModal';
import SaveReportAsModal from '../../../reports-tab/reports-page/actions/save-report-as/SaveReportAsModal';
import SaveReportModal from '../../../reports-tab/reports-page/actions/save-report/SaveReportModal';
import {reportPermissions} from '../../util/PermissionsUtil';
import BulkShareReportModal from '../../../reports-tab/reports-page/actions/share-report/BulkShareReportModal';
import PhaseManagementModal from '../../../../containers/modal/PhaseManagementModal';
import DeletePhaseModal from '../../../../containers/modal/DeletePhaseModal';
import ManageDeliverablesModal from '../../../../components/baseline/ManageDeliverablesModal';
import DeliverableEditorModal from '../../../../components/baseline/DeliverableEditorModal';
import DeleteDeliverableConfirmationModal from '../../../../components/baseline/DeleteDeliverableConfirmationModal';
import ExitActionBarModal from '../../../../containers/modal/ExitActionBarModal';
import HiBobImportTeamMembersModal from '../../../../containers/modal/hibob/HiBobImportTeamMembersModal';
import ChooseSeatModal from '../../../../containers/modal/hibob/ChooseSeatModal';

export const closeModal = () => dispatch(EVENT_ID.CLOSE_MODAL);
export const showModal = (data, closeOtherModals) => dispatch(EVENT_ID.SHOW_MODAL, data, closeOtherModals);

export const MODAL_TYPE = {
	CREATE_PROJECT: 'createProject',
	DELETE_PROGRAM: 'deleteProgram',
	CREATE_PROGRAM: 'createProgram',
	CHANGE_PROGRAM_BUDGET_TYPE: 'changeProgramBudgetType',
	CHANGE_PROGRAM_BUDGET_VALUE: 'changeProgramBudgetValue',
	LEAVE_ALLOCATIONS_PAGE: 'leaveAllocationsPage',
	PROGRAM_BUDGET_TYPE_CHANGED: 'programBudgetTypeChanged',
	PROGRAM_ALLOCATIONS_CHANGED: 'programAllocationsChanged',
	IMPORT_PROJECT_TO_PROGRAM: 'importProjectToProgram',
	EXTERNAL_REPORT_SHARE: 'externalReportShare',
	PROJECT_AUTOMATE: 'projectAutomate',
	CANVAS_CREATE: 'CANVAS_CREATE',
	PROJECT_SCHEDULING: 'projectScheduling',
	NEW_TASK: 'Nask',
	FILE_LOCATION: 'fileLocation',
	USER_INPUT: 'userInput',
	PROJECT_BUDGET_CHANGE: 'projectBudgetChange',
	NOT_PERMITTED_PROJECT_BUDGET_CHANGE: 'notPermittedProjectBudgetChange',
	PROGRAM_BUDGET_ERROR_MESSAGE: 'programBudgetErrorMessage',
	SELECT: 'select',
	SELECT_V2: 'selectV2',
	BULK_TASK_UPDATE: 'bulkTaskUpdate',
	NEW_PROJECT_V2: 'newProjectV2',
	NEW_PLACEHOLDER: 'newPlaceholder',
	DELETE_PLACEHOLDER_CONFIRMATION: 'deletePlaceholder',
	PLACEHOLDER_ALLOCATION: 'placeholderAllocation',
	PLACEHOLDER_DIVIDE_ALLOCATION: 'placeholderDivideAllocation',
	REPLACE_PLACEHOLDER_WITH_PERSON: 'replacePlaceholderWithPerson',
	TRANSFER_PLACEHOLDER_ALLOCATION_TO_PERSON: 'transferPlaceholderAllocationToPerson',
	ASSIGN_TO_PERSON: 'assignToPerson',
	PLACEHOLDER_STAFFING_UNSAVED_CHANGES: 'placeholderStaffingUnsavedChanges',
	SET_TO_RUNNING_REMINDER: 'setToRunningReminder',
	MUTATION_ERROR: 'mutationError',
	MUTATION_ERROR_NON_CRITICAL: 'mutationErrorNonCritical',
	BOUNDARY_ERROR: 'boundaryError',
	ADD_TEAM: 'addTeam',
	ADD_DEPARTMENT: 'addDepartment',
	ADD_USER: 'addUser',
	ADD_PROJECT_GROUP: 'addProjectGroup',
	ADD_CLIENT_USER: 'AddClientUser',
	ADD_CLIENT: 'AddClient',
	EXPENSE_ITEM: 'ExpenseItem',
	EXPENSE_ITEM_V2: 'ExpenseItemV2',
	IMPORT_EXPENSE_ITEM: 'importExpenseItem',
	IMPORT_SAGE_INTACCT_EXPENSE_ITEM: 'importSageIntacctExpenseItem',
	CHANGE_EXPENSE_CATEGORY: 'ChangeExpenseCategory',
	CHANGE_EXPENSE_APPROVAL: 'ChangeExpenseApproval',
	CHANGE_EXPENSE_BILLABLE_STATUS: 'ChangeExpenseBillableStatus',
	CHANGE_EXPENSE_FIXED_PRICE_BILLING: 'changeExpenseFixedPriceBilling',
	TASK_RECURRENCE: 'taskRecurrence',
	GENERIC: 'generic',
	PROJECT_SCHEDULING_V2: 'projectSchedulingV2',
	PROJECT_PHASE_CAPACITY: 'PROJECT_PHASE_CAPACITY',
	ALLOCATION_V2: 'allocationV2',
	ALLOCATE_TEAM_MEMBERSV2: 'allocateTeamMembersV2',
	EXPAND_FILE: 'fileModal',
	IMPORT_HOLIDAY_CALENDAR_ENTRIES: 'importHolidayCalendarEntries',
	CANVAS_CREATE_NEW_MODAL: 'canvasCreateNewModal',
	SALESFORCE_SYNC_CLIENTS: 'salesforceSyncClients',
	HUBSPOT_SYNC_CLIENTS: 'hubspotSyncClients',
	PIPEDRIVE_SYNC_CLIENTS: 'pipedriveSyncClients',
	CANVAS_UTILIZATION: 'canvasUtilization',
	STANDALONE_CANVAS_UTILIZATION: 'standaloneCanvasUtilization',
	SCHEDULING_UTILIZATION: 'schedulingUtilization',
	CREATE_INVOICE_V2: 'CreateInvoiceV2',
	EDIT_INVOICE_V2: 'EditInvoiceV2',
	CREATE_INVOICE_V2_STANDALONE: 'StandaloneCreateInvoice',
	RETAINER_BULK_INVOICE_CREATE: 'RetainerBulkInvoiceCreate',
	ADD_BILL_TO_ENTITY: 'AddBillToEntity',
	EDIT_CLIENT_DETAILS: 'EditClientDetails',
	ASSOCIATE_BILL_TO_ENTITY: 'AssociateBillToEntity',
	EXPORT_INVOICE_BILLING_OPTION: 'ExportInvoiceBillingOption',
	PROJECTS_BILLING_DETAILS_WARNING: 'ProjectsBillingDetailsWarning',
	MISSING_CLIENT_BILL_TO_WARNING: 'MissingClientBillToWarning',
	EDIT_CLIENT_NOTES: 'EditClientNotes',
	ACTION_COMPLETE_GO_SOMEWHERE: 'ActionCompleteGoSomewhere',
	TIMER: 'timer',
	COPY_TIME: 'copyTime',
	EXPORT_INVOICE_QUICKBOOKS: 'ExportInvoiceQuickbooks',
	WARNING: 'warning',
	SEAT_MANAGEMENT: 'seatManagement',
	ADD_TEAM_MEMBER: 'addTeamMemberModalActual',
	AUTO_SCHEDULING: 'autoScheduling',
	GENERIC_DELETION_WARNING: 'genericDeletionWarningModal',
	TASK_DELETION_WARNING: 'taskDeletionWarningModal',
	PERSON_DEACTIVATE_WARNING: 'personDeactivateWarningModal',
	HARVEST_TASK_SELECT: 'harvestTaskSelect',
	UNIT4_ACTIVITY_SELECT: 'unit4ActivitySelect',
	COST_PERIODS: 'costPeriods',
	ADD_PROJECT_DATES: 'addProjectDates',
	FIX_UNASSIGNED_TASKS: 'fixUnassignedTasks',
	FIX_UNESTIMATED_TASKS: 'fixUnestimatedTasks',
	TIME_REGS_ON_PROJECT: 'timeRegsOnProject',
	TASK_OUTSIDE_PROJECT_PERIOD: 'taskOutsideProjectPeriod',
	TIME_REGS_OUTSIDE_PROJECT_PERIOD: 'timeRegsOutsideProjectPeriod',
	EXPENSES_OUTSIDE_PROJECT_PERIOD: 'expensesOutsideProjectPeriod',
	COST_RATE: 'costRate',
	CHANGE_FIXED_PRICE: 'changeFixedPrice',
	UPDATE_FIXED_PRICE: 'updateFixedPrice',
	CONFIRM_UPDATE_FIXED_PRICE: 'confirmUpdateFixedPrice',
	CREATE_JIRA_ISSUE: 'createJiraIssue',
	CREATE_JIRA_MILESTONE: 'createJiraMilestone',
	CREATE_ADO_WORKITEM: 'createAdoWorkitem',
	SPRINT_UNFINISHED_WORK: 'sprintUnfinished',
	ADD_EMAIL: 'addEmail',
	BUDGET_FIXED_PRICE: 'budgetFixedPrice',
	WARNING_RADIO_BUTTON_MODAL: 'warningRadioButtonModal',
	RETAINER_TIME_ENTRY_CONFLICT_HANDLING: 'retainerTimeEntryConflictHandling',
	PERMISSIONS_INFO: 'permissionsInfo',
	TASK_LOCATION: 'taskLocation',
	ADD_PERSON_TO_PROJECT_MODAL: 'personLocation',
	COLUMN_SELECTION: 'columnSelection',
	PHASE_LOCATION: 'phaseLocation',
	PROJECT_STATUS: 'projectStatus',
	PROJECT_PROGRESS: 'projectProgress',
	ADD_RATE_CARD: 'addRateCard',
	ADD_PERIODS: 'addPeriods',
	CHANGE_PERIOD: 'changePeriod',
	TASK_MODAL: 'taskModal',
	SYNC_JIRA_PROJECT: 'syncJiraProject',
	LINK_ADO_PROJECT: 'linkADOProject',
	CHANGE_ALLOCATION_TYPE: 'changeAllocationType',
	CHANGE_COMPANY_WEEKEND_VISIBILITY: 'changeCompanyWeekendVisibility',
	LINK_UNIT4_PROJECT: 'linkUnit4Project',
	UNIT4_SYNC_USERS: 'unit4SyncUsers',
	JIRA_SYNC_USERS: 'jiraSyncUsers',
	BAMBOO_IMPORT_USERS: 'bambooImportUsers',
	ADO_IMPORT_USERS: 'adoImportUsers',
	INTEGRATIONS_INFO: 'integrationsInfo',
	ADO_LINK_USER: 'adoLinkUser',
	BAMBOO_IMPORT_TIME_OFF_TYPES: 'bambooImportTimeOffTypes',
	XERO_SYNC_CLIENTS: 'xeroSyncClients',
	ECONOMIC_SYNC_CLIENTS: 'economicSyncClients',
	SAGE_INTACCT_SYNC_CLIENTS: 'sageIntacctSyncClients',
	QUICKBOOKS_SYNC_CLIENTS: 'quickbooksSyncClients',
	SAVE_DEMO_ENVIRONMENT: 'saveDemoEnvironment',
	LOAD_DEMO_ENVIRONMENT: 'loadDemoEnvironment',
	CONFIRM_PASSWORD_MODAL: 'confirmPasswordModal',
	CLOSE_SPRINT: 'close_sprint',
	CLOSE_PROJECT: 'close_project',
	UPDATE_SPRINT_PERFORMANCE: 'update_sprint_performance',
	WARNING_DATE_LOCKED: 'warning_date_locked',
	ROLE_USED_IN_TIME_REG: 'role_used_in_time_reg',
	XERO_EXPORT: 'xero_export',
	QUICKBOOKS_EXPORT: 'quickbooks_export',
	ECONOMIC_EXPORT: 'economic_export',
	SAGE_INTACCT_IMPORT_DEPARTMENTS: 'sageIntacct_importDepartments',
	SAGE_INTACCT_EXPORT_TIMESHEETS: 'sageIntacct_exportTimesheets',
	SAGE_INTACCT_IMPORT_USERS: 'sageIntacct_importUsers',
	SAGE_INTACCT_EXPORT_TASKS: 'sageIntacct_exportTasks',
	SAGE_INTACCT_EXPORT: 'sageIntacct_export',
	SAGE_INTACCT_SYNC_PROJECT: 'sageIntacct_syncProject',
	SAGE_INTACCT_CREATE_NEW_PROJECT: 'sageIntacct_createNewProject',
	APPROVE_AND_LOCK_BASELINE: 'apprive_and_lock_baseline',
	JIRA_EDIT_EPICS: 'jira_edit_epics',
	JIRA_REMOVE_EPICS: 'jira_remove_epics',
	ACTIVATE_TIME_APPROVAL: 'activate_time_approval',
	ACTIVATE_TIME_OFF_APPROVAL: 'activate_time_off_approval',
	SUBMIT_TIME_APPROVAL: 'submit_time_approval',
	SUBMIT_TIME_OFF_APPROVAL: 'submit_time_off_approval',
	UNSUBMIT_TIME_APPROVAL: 'unsubmit_time_approval',
	INTEGRATION_INCOMPATIBLE: 'integration_incompatible',
	TIME_LOCK: 'time_lock',
	TEXT_BUTTONS: 'text_buttons',
	REJECT_TIMEAPPROVAL_SUBMISSION: 'reject_timeapproval_submission',
	NO_AVAILABLE_SEATS: 'no_available_seats',
	JIRA_START_DATE: 'jira_start_date',
	XERO_INACCESSIBLE: 'xero_inaccesible',
	CONFIRM_PROJECT_DELETION: 'confirm_project_deletion',
	CONFIRM_SKILL_DELETION: 'confirm_skill_deletion',
	CONFIRM_SKILL_CATEGORY_DELETION: 'confirm_skill_category_deletion',
	CREATE_SKILL: 'create_skill',
	UPDATE_SKILL: 'update_skill',
	CREATE_SKILL_CATEGORY: 'create_skill_category',
	UPDATE_SKILL_CATEGORY: 'update_skill_category',
	DELETE_SKILL_LEVEL: 'delete_skill_level',
	MANAGE_SKILL_LEVELS: 'manage_skill_levels',
	ENABLE_SKILL_LEVELS_INFO_MODAL: 'enable_skill_levels_info_modal',
	PERSON_SKILLS: 'person_skills',
	CREATE_LABEL: 'create_label',
	UPDATE_LABEL: 'update_label',
	CREATE_LABEL_CATEGORY: 'create_label_category',
	MERGE_LABELS: 'merge_labels',
	BULK_ASSIGN_SKILLS_TO_PERSONS: 'assign_skills_to_persons',
	BULK_MOVE_SKILLS_TO_CATEGORY: 'move_skills_to_category',
	PROFILE: 'profile',
	DOWNLOADING_QUERY_DATA: 'downloading_query_data',
	DOWNLOADING_QUERY_DATA_TASK: 'downloading_query_data_task',
	REPORTED_TIME_DATE_CSV: 'reported_time_date_csv',
	DOWNLOADING_CSV_DATA: 'downloading_csv_data',
	FIXED_PRICE_LOCK: 'fixed_price_lock',
	FIXED_PRICE_OPEN_LOCK: 'fixed_price_open_lock',
	BASELINE_ADJUST_MODAL: 'baseline_adjust_modal',
	TIMER_V3: 'timer_v3',
	RATE_CARD: 'rate_card',
	TASK_STATUS_MICRO_ASSIST: 'task_status_micro_assist',
	BULK_TASK_UPDATE_STANDALONE: 'bulkTaskUpdateStandalone',
	PREDICTED_END_DATE: 'predictedEndDate',
	ALLOCATION_ASSISTANCE: 'allocationAssistance',
	TEAM_FOCUS: 'teamFocus',
	PERFORMANCE: 'performance',
	FORECAST_SURPLUS: 'forecastSurplus',
	PROJECT_STATUS_V2: 'projectStatusV2',
	PROGRAM_STATUS: 'programStatus',
	CONVERT_OLD_SUBTASKS: 'convertOldSubtasks',
	CONFIRM_LABEL_DELETION: 'confirm_label_deletion',
	CONFIRM_LABEL_CATEGORY_DELETION: 'confirm_label_category_deletion',
	UPDATE_LABEL_CATEGORY: 'update_label_category',
	BULK_MOVE_LABELS_TO_CATEGORY: 'bulk_move_labels_to_category',
	CUSTOM_FIELD_DEFINITION: 'custom_field_definition',
	DELETE_PRIORITY_LEVEL: 'delete_priority_level,',
	DETACH_PROJECT_MODAL: 'detachProject',
	REMOVE_PROGRAM_MEMBER_MODAL: 'removeProgramMember',
	SETTINGS_SETUP_CONFIRM_TOGGLE_REVENUE: 'settings_setup_confirm_toggle_revenue',
	SETTINGS_SETUP_CONFIRM_TOGGLE_TIMESHEETS: 'settings_setup_confirm_toggle_timesheets',
	SETTINGS_SETUP_CONFIRM_TOGGLE_CLIENTS: 'settings_setup_confirm_toggle_clients',
	PROJECT_SETTINGS_EDIT_RATE_AND_COST_LITE: 'project_settings_edit_rate_and_cost_lite',
	UPDATE_CONTACT_TYPE: 'update_contact_type',
	CONFIRM_GENERIC: 'confirm_generic',
	RATE_CARD_ROLE_DEACTIVATE_WARNING: 'rateCardRoleDeactivateWarning',
	ROLE_DEACTIVATED_WARNING: 'roleDeactivatedWarning',
	CHANGE_CAP_SETTING: 'changeCapSetting',
	OWNED_PROJECTS: 'ownedProjects',
	CREATE_TIME_REGISTRATION: 'createTimeRegistration',
	UPDATE_TIME_REGISTRATION: 'updateTimeRegistration',
	TIMER_TIME_REGISTRATION: 'timerTimeRegistration',
	RELINK_BASELINE_SCOPING_DATES: 'relinkBaselineScopingDates',
	COPY_FROM_LAST_WEEK: 'copyFromLastWeek',
	CLIENT_PERMISSIONS: 'client_permissions',
	ASSOCIATE_BILL_TO: 'AssociateBillTo',
	UNASSOCIATE_BILL_TO: 'UnassociateBillTo',
	REMOVE_BILL_TO: 'RemoveBillTo',
	REMOVE_PROJECT_BILL_TO: 'RemoveProjectBillTo',
	REMOVE_CLIENT_BILL_TO: 'RemoveCLientBillTo',
	ADD_PROJECT_BILL_TO: 'AddProjectBillTo',
	ASSOCIATE_CLIENT_TO_PROJECT: 'AssociateClientToProject',
	DISABLE_INTEGRATION_MODAL: 'DisableIntegrationModal',
	EXPORT_INVOICE: 'ExportInvoice',
	INVOICED_BILL_TO_WARNING: 'InvoicedBillToWarning',
	WARNING_CLIENT_CHANGE_EXPORTED_INVOICE_BILL_TO_MISMATCH: 'WarningClientChangeExportedInvoiceBillToMismatch',
	GOOGLE_CALENDAR_OAUTH_ERROR: 'GoogleCalendarOauthError',
	SHOW_HIDE_COLUMNS: 'ShowHideColumns',
	EXPENSE_ITEM_CHANGE_PROJECT_PHASE_MODAL: 'ExpenseItemChangeProjectPhaseModal',
	EXPENSE_MANAGEMENT_DELETE_EXPENSES: 'ExpenseManagementDeleteExpenses',
	EXPENSE_MANAGEMENT_DELETE_EXPENSE: 'ExpenseManagementDeleteExpense',
	EXPENSE_MANAGEMENT_CHANGE_MARKUP: 'ExpenseManagementChangeMarkup',
	EXPENSE_ITEM_CHANGE_PERSON_MODAL: 'ExpenseItemChangePersonModal',
	REVERT_ROLLOVER_MODAL: 'RevertRolloverModal',
	DELETE_RETAINER_PERIOD_MODAL: 'DeleteRetainerPeriodModal',
	RENAME_REPORT_MODAL: 'RenameReportModal',
	CONFIRMATION: 'Confirmation',
	DELETE_REPORT_MODAL: 'DeleteReportModal',
	SHARE_REPORT_MODAL: 'ShareReportModal',
	BULK_SHARE_REPORT_MODAL: 'BulkShareReportModal',
	TRANSFER_REPORT_OWNERSHIP_MODAL: 'TransferReportOwnershipModal',
	PHASE_MANAGEMENT_MODAL: 'PhaseManagementModal',
	DELETE_PHASE_MODAL: 'DeletePhaseModal',
	CREATE_REPORT_MODAL: 'CreateReportModal',
	SAVE_REPORT_AS_MODAL: 'SaveReportAsModal',
	SAVE_REPORT_MODAL: 'SaveReportModal',
	MANAGE_DELIVERABLES_MODAL: 'ManageDeliverablesModal',
	DELIVERABLE_EDITOR_MODAL: 'DeliverableEditorModal',
	DELETE_DELIVERABLE_CONFIRMATION_MODAL: 'DeleteDeliverableConfirmationModal',
	EXIT_ACTION_BAR_MODAL: 'ExitActionBarModal',
	HIBOB_IMPORT_TEAM_MEMBERS_MODAL: 'HiBobImportTeamMembersModal',
	CHOOSE_SEAT_MODAL: 'ChooseSeatModal',
};

class GenericModalConductor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalData: [],
		};
		this.handleEscClick = this.handleEscClick.bind(this);

		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		subscribe(EVENT_ID.SHOW_MODAL, this.showModal);
	}

	componentDidMount() {
		subscribe(EVENT_ID.CLOSE_MODAL, this.closeModal);
		window.addEventListener('keydown', this.handleEscClick);
		window.addEventListener('popstate', this.closeModal);
	}

	componentWillUnmount() {
		unsubscribe(EVENT_ID.SHOW_MODAL, this.showModal);
		unsubscribe(EVENT_ID.CLOSE_MODAL, this.closeModal);
		window.removeEventListener('keydown', this.handleEscClick);
		window.removeEventListener('popstate', this.closeModal);
	}

	closeModal() {
		return new Promise(resolve => {
			if (this.state.modalData.length > 0) {
				const modalData = this.state.modalData;
				const modalProps = modalData.pop();
				this.setState({modalData});
				if (modalProps.onCloseModal) {
					modalProps.onCloseModal();
				}
			}
			resolve();
		});
	}

	handleEscClick(e) {
		if (e.key === 'Escape') {
			const openModals = this.getOpenModals();
			// check if only open modal is taskModal
			if (openModals.length === 1 && openModals.some(modal => modal.type === 'taskModal')) {
				const path = window.location.pathname;
				if (pathIncludesTask(path)) {
					// Task modal is open. Redirect.
					this.props.history.push(removeTaskLinkFromUrl(path));
				}
			} else {
				this._timeoutID = setTimeout(() => {
					this.closeModal();
				}, 0);
			}
		}
	}

	showModal(data, closeOtherModals = false) {
		let modalData = this.state.modalData;
		if (closeOtherModals) {
			modalData = [data];
		} else {
			modalData.push(data);
		}
		this.setState({modalData: modalData});
	}

	getOpenModals() {
		return this.state.modalData;
	}

	getModalHtml(modalData) {
		const {type} = modalData;

		if (!type) {
			return null;
		}
		switch (type) {
			case MODAL_TYPE.BASELINE_ADJUST_MODAL: {
				return <AdjustBaselineModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			}
			case MODAL_TYPE.CONFIRM_LABEL_DELETION:
			case MODAL_TYPE.CONFIRM_LABEL_CATEGORY_DELETION:
			case MODAL_TYPE.CONFIRM_PROJECT_DELETION:
			case MODAL_TYPE.CONFIRM_SKILL_CATEGORY_DELETION:
			case MODAL_TYPE.CONFIRM_SKILL_DELETION: {
				return <TypeToDeleteModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			}
			case MODAL_TYPE.CONFIRM_GENERIC:
				return <TypeConfirmModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.PERSON_SKILLS:
				return (
					<ForecastQueryRenderer
						key="query-render-person-skill-modal"
						query={personSkillsModalQuery}
						modal={true}
						variables={{personId: modalData.personId}}
						render={relayProps => {
							return (
								<PersonSkillModal company={relayProps.viewer.company} closeModal={this.closeModal.bind(this)} />
							);
						}}
					/>
				);
			case MODAL_TYPE.APPROVE_AND_LOCK_BASELINE: {
				return <ApproveAndLockModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			}
			case MODAL_TYPE.PROJECT_AUTOMATE: {
				return <ProjectAutomateModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			}
			case MODAL_TYPE.REJECT_TIMEAPPROVAL_SUBMISSION:
				return <RejectSubmissionModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.NEW_TASK:
				return (
					<ForecastQueryRenderer
						key="query-render-task-location-modal"
						query={taskLocationModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<TaskLocationModal
									{...relayProps}
									closeModal={this.closeModal.bind(this)}
									showTaskModal={modalData.showTaskModal}
									isOpenedByShortcut={modalData.isOpenedByShortcut}
									currentProjectId={modalData.currentProjectId}
									currentProjectGroupId={modalData.currentProjectGroupId}
									projectGroupStatusColumnId={modalData.projectGroupStatusColumnId}
									taskId={modalData.taskId}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ADD_PROJECT_GROUP:
				return (
					<ForecastQueryRenderer
						key="query-render-project-group-modal"
						query={addProjectGroupModalQuery}
						modal={true}
						render={relayProps => {
							return <AddProjectGroupModal {...relayProps} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.FILE_LOCATION:
				return (
					<FileLocationModal
						closeModal={this.closeModal.bind(this)}
						cards={modalData.cards}
						handleFileMoveToCard={modalData.handleFileMoveToCard}
						file={modalData.file}
						taskId={modalData.taskId}
					/>
				);
			case MODAL_TYPE.USER_INPUT:
				return (
					<UserInputModal
						closeModal={this.closeModal.bind(this)}
						handleConfirm={modalData.handleConfirm}
						titleText={modalData.titleText}
						confirmText={modalData.confirmText}
						cancelText={modalData.cancelText || null}
						initialValue={modalData.initialValue}
						label={modalData.label}
						focusOnMount={modalData.focusOnMount}
						inputType={modalData.inputType}
						currency={modalData.currency}
						suffix={modalData.suffix}
						placeholder={modalData.placeholder}
						subHeaderText={modalData.subHeaderText}
						hoursInputType={modalData.hoursInputType}
						disableOnInvalidInput={modalData.disableOnInvalidInput}
					/>
				);
			case MODAL_TYPE.ADD_PERIODS:
				return <AddPeriodsModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CHANGE_PERIOD:
				return <ChangePeriodTargetModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.PROJECT_BUDGET_CHANGE:
				if (hasTopDownProgramBudgetFeature()) {
					return <ProjectBudgetChangeModal closeModal={this.closeModal.bind(this)} {...modalData} />;
				} else {
					return <ProjectBudgetChangeModalOld closeModal={this.closeModal.bind(this)} {...modalData} />;
				}

			case MODAL_TYPE.NOT_PERMITTED_PROJECT_BUDGET_CHANGE:
				return <NotPermittedProjectBudgetChangeModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE:
				return <ProgramBudgetErrorMessage closeModal={this.closeModal.bind(this)} {...modalData} />;

			case MODAL_TYPE.SELECT:
				return (
					<SelectModal
						closeModal={this.closeModal.bind(this)}
						options={modalData.options}
						title={modalData.title}
						label={modalData.label}
						multi={modalData.multi}
						initialValue={modalData.initialValue}
						defaultCallback={modalData.defaultCallback}
						projectSelect={modalData.projectSelect}
						personSelect={modalData.personSelect}
					/>
				);
			case MODAL_TYPE.SELECT_V2:
				return (
					<SelectModalV2
						closeModal={this.closeModal.bind(this)}
						options={modalData.options}
						title={modalData.title}
						subTitle={modalData.subTitle}
						label={modalData.label}
						emptyText={modalData.emptyText}
						multi={modalData.multi}
						initialValue={modalData.initialValue}
						defaultCallback={modalData.defaultCallback}
						projectSelect={modalData.projectSelect}
						personSelect={modalData.personSelect}
					/>
				);
			case MODAL_TYPE.BULK_TASK_UPDATE:
				return (
					<BulkTaskUpdateModal
						closeModal={this.closeModal.bind(this)}
						language={modalData.language}
						useTaskFollowers={modalData.useTaskFollowers}
						role_options={modalData.role_options}
						people_options={modalData.people_options}
						status_options={modalData.status_options}
						labels={modalData.labels}
						getOptions={modalData.getOptions}
						tasks={modalData.tasks}
						estimationUnit={modalData.estimationUnit}
						projectGroupId={modalData.projectGroupId}
						saveCallback={modalData.saveCallback}
						isJiraProject={modalData.isJiraProject}
						companyId={modalData.companyId}
						isClientUser={modalData.isClientUser}
						availableFeatureFlags={modalData.availableFeatureFlags}
					/>
				);
			case MODAL_TYPE.BULK_TASK_UPDATE_STANDALONE:
				return (
					<ForecastQueryRenderer
						key="query-render-bulk-task-update-standalone"
						query={BulkTaskUpdateModalWrapperQuery}
						modal={true}
						variables={{projectId: modalData.projectId, projectGroupId: modalData.projectGroupId}}
						render={relayProps => {
							return (
								<BulkTaskUpdateModalWrapper
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.NEW_PROJECT_V2:
				return (
					<ForecastQueryRenderer
						key="query-render-newProjectModal"
						query={newProjectModalV2Query}
						modal={true}
						variables={{
							projectId: modalData.projectIdToAddPeople || '',
							fetchProject: !!modalData.projectIdToAddPeople,
						}}
						fetchPolicy={'network-only'} //https://app.forecast.it/T29136
						render={relayProps => {
							return <NewProjectModalV2 {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.NEW_PLACEHOLDER:
				return (
					<ForecastQueryRenderer
						key="query-render-PlaceholderModal"
						query={placeholderModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							placeholderId: modalData.placeholderId || '',
							loadPlaceholder: !!modalData.placeholderId,
						}}
						render={relayProps => {
							return <PlaceholderModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.DELETE_PLACEHOLDER_CONFIRMATION:
				return <DeletePlaceholderConfirmationModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.PLACEHOLDER_ALLOCATION:
				return (
					<ForecastQueryRenderer
						key="query-render-PlaceholderAllocationModal"
						query={placeholderAllocationModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							projectId: modalData.projectId || '',
							projectGroupId: modalData.projectGroupId || '',
							personId: modalData.placeholderAllocationInput?.personId,
							placeholderId: modalData.placeholderInput.id,
							placeholderAllocationId: modalData.placeholderAllocationInput?.id,
							loadProject: !!modalData.projectId,
							loadProjectGroup: !!modalData.projectGroupId,
							loadPlaceholder: !!modalData.placeholderInput.id && !modalData.staffingModeActive,
							loadAllocation: !!modalData.placeholderAllocationInput?.id && !modalData.staffingModeActive,
							loadPerson: !!modalData.placeholderAllocationInput?.personId,
						}}
						render={relayProps => {
							return (
								<PlaceholderAllocationModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.PLACEHOLDER_DIVIDE_ALLOCATION:
				return (
					<ForecastQueryRenderer
						key="query-render-PlaceholderDivideAllocationModal"
						query={placeholderDivideAllocationModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							placeholderId: modalData.placeholderInput.id,
							placeholderAllocationId: modalData.placeholderAllocationInput.id,
							loadPlaceholder: !modalData.staffingModeActive,
							loadAllocation: !modalData.staffingModeActive,
						}}
						render={relayProps => {
							return (
								<PlaceholderDivideAllocationModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.REPLACE_PLACEHOLDER_WITH_PERSON:
				return (
					<ForecastQueryRenderer
						key="query-render-ReplaceWithPersonModal"
						query={replaceWithPersonModalQuery}
						variables={{
							placeholderId: modalData.placeholderId,
							loadPlaceholder: !modalData.staffingModeActive,
						}}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<ReplaceWithPersonModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.TRANSFER_PLACEHOLDER_ALLOCATION_TO_PERSON:
				return (
					<ForecastQueryRenderer
						key="query-render-TransferToPersonModal"
						query={transferToPersonModalQuery}
						variables={{
							placeholderId: modalData.placeholderInput.id,
							placeholderAllocationId: modalData.placeholderAllocationInput.id,
							loadPlaceholder: !modalData.staffingModeActive,
							loadAllocation: !modalData.staffingModeActive,
						}}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<TransferToPersonModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />
							);
						}}
					/>
				);
			case MODAL_TYPE.ASSIGN_TO_PERSON:
				return (
					<ForecastQueryRenderer
						key="query-render-AssignToPersonModal"
						query={assignToPersonModalQuery}
						variables={{
							placeholderId: modalData.placeholderInput.id,
							loadPlaceholder: !modalData.staffingModeActive,
						}}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<AssignToPersonModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />
							);
						}}
					/>
				);
			case MODAL_TYPE.PLACEHOLDER_STAFFING_UNSAVED_CHANGES:
				return <PlaceholderStaffingUnsavedChanges closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.SET_TO_RUNNING_REMINDER:
				return (
					<ForecastQueryRenderer
						key="query-render-set-to-running-modal"
						query={setToRunningReminderModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return <SetToRunningReminderModal {...relayProps} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.MUTATION_ERROR:
				const errorId = registerMutationError(modalData?.endpoint, modalData?.correlationId, modalData?.isNetworkError);

				// Send to backend for logging
				let options = '';
				if (modalData && modalData.correlationId) {
					options = `?correlationId=${modalData.correlationId}`;
				}

				if (errorId) {
					if (options !== '') {
						options += '&';
					} else {
						options += '?';
					}
					options += `sentryEventId=${errorId}`;
				}

				if (modalData && modalData.isNetworkError) {
					if (options !== '') {
						options += '&';
					} else {
						options += '?';
					}
					options += 'isNetworkError=true';
				}

				if (options !== '') {
					options += '&';
				} else {
					options += '?';
				}
				options += 'url=' + encodeURI(window.location.pathname);

				const init = {credentials: 'include'};

				fetch(DirectApi.graphqlServerEndpoint('error_modal_shown' + options), init);

				// Show modal
				return (
					<MutationErrorModal
						reload_to_upcoming={modalData.reload_to_upcoming === true}
						isNetworkError={modalData && modalData.isNetworkError}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.MUTATION_ERROR_NON_CRITICAL:
				const nonCritErrorId = registerMutationError(
					modalData?.endpoint,
					modalData?.correlationId,
					modalData?.isNetworkError,
					true
				);

				// Send to backend for logging
				let nonCriticalOptions = '';
				if (modalData && modalData.correlationId) {
					nonCriticalOptions = `?correlationId=${modalData.correlationId}`;
				}

				if (nonCritErrorId) {
					if (nonCriticalOptions !== '') {
						nonCriticalOptions += '&';
					} else {
						nonCriticalOptions += '?';
					}
					nonCriticalOptions += `sentryEventId=${nonCritErrorId}`;
				}

				if (nonCriticalOptions !== '') {
					nonCriticalOptions += '&';
				} else {
					nonCriticalOptions += '?';
				}
				nonCriticalOptions += 'isNonCriticalError=true';

				if (nonCriticalOptions !== '') {
					nonCriticalOptions += '&';
				} else {
					nonCriticalOptions += '?';
				}
				nonCriticalOptions += 'url=' + encodeURI(window.location.pathname);

				const initObj = {credentials: 'include'};

				fetch(DirectApi.graphqlServerEndpoint('error_modal_shown' + nonCriticalOptions), initObj);

				// Show modal
				return (
					<MutationErrorModal
						errorType={modalData && modalData.errorType}
						isNonCriticalError
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.BOUNDARY_ERROR:
				// Show modal
				return (
					<BoundaryErrorModal modalType={this.state.modalData.modalType} closeModal={this.closeModal.bind(this)} />
				);
			case MODAL_TYPE.ADD_TEAM:
				return (
					<AddTeamModal
						closeModal={this.closeModal.bind(this)}
						persons={modalData.persons}
						companyId={modalData.companyId}
						initialTeam={modalData.initialTeam}
						teams={modalData.teams}
						projectId={modalData.projectId}
						projectGroupId={modalData.projectGroupId}
						callbackPositive={modalData.callbackPositive}
						team={modalData.team}
						allPersons={modalData.allPersons}
					/>
				);
			case MODAL_TYPE.ADD_DEPARTMENT:
				return (
					<AddTeamModal
						closeModal={this.closeModal.bind(this)}
						persons={modalData.persons}
						companyId={modalData.companyId}
						initialTeam={modalData.initialTeam}
						teams={modalData.teams}
						projectId={modalData.projectId}
						projectGroupId={modalData.projectGroupId}
						callbackPositive={modalData.callbackPositive}
						team={modalData.team}
						allPersons={modalData.allPersons}
						addDepartment={true}
					/>
				);

			case MODAL_TYPE.IMPORT_HOLIDAY_CALENDAR_ENTRIES:
				return (
					<ImportHolidayCalendarEntriesModal
						closeModal={this.closeModal.bind(this)}
						holidayCalendarId={modalData.holidayCalendarId}
					/>
				);
			case MODAL_TYPE.ADD_CLIENT_USER:
				return (
					<ForecastQueryRenderer
						key="query-render-addClientUserModal"
						query={addClientUserModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<AddClientUserModal
									{...relayProps}
									isModal={true}
									addClientUser={modalData.addClientUser}
									project={modalData.project}
									projectId={modalData.projectId}
									project_options={modalData.project_options}
									closeModal={this.closeModal.bind(this)}
									companyId={modalData.companyId}
									clientId={modalData.clientId}
									editClientUser={modalData.editClientUser}
									clientUser={modalData.clientUser}
									callbackPositive={modalData.callbackPositive}
									buttonLocation={modalData.buttonLocation}
									projectGroupId={modalData.projectGroupId}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ADD_TEAM_MEMBER:
				return (
					<ForecastQueryRenderer
						key="query-render-addTeamMemberModal"
						query={addTeamMemberModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<AddTeamMemberModal
									{...relayProps}
									closeModal={this.closeModal.bind(this)}
									projectId={modalData.projectId}
									projectGroupId={modalData.projectGroupId}
									useManualAllocations={modalData.useManualAllocations}
									buttonLocation={modalData.buttonLocation}
								/>
							);
						}}
					/>
				);
			//return <AddTeamMemberModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.AUTO_SCHEDULING:
				return (
					<ForecastQueryRenderer
						key="query-render-autoScheduling"
						query={autoSchedulingModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<AutoSchedulingModal {...relayProps} closeModal={this.closeModal.bind(this)} {...modalData} />
							);
						}}
					/>
				);
			case MODAL_TYPE.EXPENSE_ITEM:
				return (
					<ForecastQueryRenderer
						key="query-render-expenseItemModal"
						query={expenseItemModalQuery}
						modal={true}
						variables={{expenseItemId: modalData.expenseItemId ? modalData.expenseItemId : ''}}
						render={relayProps => {
							return (
								<ExpenseItemModal
									{...relayProps}
									closeModal={this.closeModal.bind(this)}
									projectId={modalData.projectId}
									selectedTab={modalData.selectedTab}
									onSave={modalData.onSave}
									handleNewExpense={modalData.handleNewExpense}
									locked={modalData.locked}
									readOnly={modalData.readOnly}
									expenseToImport={modalData.expenseToImport}
									fromXero={modalData.expenseToImport}
									fromSageIntacct={modalData.fromSageIntacct}
									onDateChange={modalData.onDateChange}
								/>
							);
						}}
					/>
				);

			case MODAL_TYPE.CHANGE_EXPENSE_APPROVAL:
				return <ChangeExpenseApprovalModal closeModal={this.closeModal.bind(this)} {...modalData} />;

			case MODAL_TYPE.CHANGE_EXPENSE_BILLABLE_STATUS:
				return <ChangeExpenseBillableStatusModal closeModal={this.closeModal.bind(this)} {...modalData} />;

			case MODAL_TYPE.CHANGE_EXPENSE_FIXED_PRICE_BILLING:
				return <ChangeExpenseFixedPriceBillingModal closeModal={this.closeModal.bind(this)} {...modalData} />;

			case MODAL_TYPE.CHANGE_EXPENSE_CATEGORY:
				return (
					<ForecastQueryRenderer
						key="query-render-ChangeExpenseCategoryModalQuery"
						query={ChangeExpenseCategoryModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<ChangeExpenseCategoryModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);

			case MODAL_TYPE.IMPORT_EXPENSE_ITEM:
				return (
					<ForecastQueryRenderer
						key="query-render-importExpenseItemModal"
						query={importExpenseItemModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<ImportExpenseItemModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);

			case MODAL_TYPE.IMPORT_SAGE_INTACCT_EXPENSE_ITEM:
				return (
					<ForecastQueryRenderer
						key="query-render-importSageIntacctExpenseItemModal"
						query={importSageIntacctExpenseItemModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<ImportSageIntacctExpenseItemModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);

			case MODAL_TYPE.RATE_CARD:
				return (
					<ForecastQueryRenderer
						key="query-render-rateCardModal"
						query={rateCardModalQuery}
						modal={true}
						variables={{rateCardId: modalData.rateCardId || ''}}
						render={relayProps => {
							return <RateCardModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.TASK_RECURRENCE:
				return <TaskRecurrenceModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.ADD_CLIENT:
				return (
					<ForecastQueryRenderer
						key="query-render-addClientModal"
						query={addClientModalQuery}
						modal={true}
						render={relayProps => {
							return <AddClientModal {...relayProps} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.GENERIC:
				return (
					<GenericModal
						top={true}
						closeModal={this.closeModal.bind(this)}
						buttons={modalData.buttons}
						headerText={modalData.headerText}
						content={modalData.content}
						defaultCallback={modalData.defaultCallback}
						className={modalData.className}
						cy={modalData.cy}
						{...modalData}
					/>
				);
			case MODAL_TYPE.CANVAS_UTILIZATION:
				return <CanvasUtilizationModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.STANDALONE_CANVAS_UTILIZATION:
				return <StandaloneUtilizationModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.SCHEDULING_UTILIZATION:
				return <UtilizationModalV2 {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.PROJECT_PHASE_CAPACITY:
				return <ProjectPhaseCapacityModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.PROJECT_SCHEDULING_V2:
				return <ProjectSchedulingModalV2 closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CANVAS_CREATE:
				return <CanvasCreateModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.ALLOCATE_TEAM_MEMBERSV2:
				return (
					<ForecastQueryRenderer
						key="query-render-allocateTeamMembersModal"
						query={allocateTeamMembersModalV2Query}
						modal={true}
						variables={{
							projectId: modalData.projectId ? modalData.projectId : '',
							groupId: modalData.projectGroupId ? modalData.projectGroupId.toString() : '',
							fetchProject:
								modalData.projectId !== '' && modalData.projectId !== undefined && modalData.projectId !== null,
						}}
						render={relayProps => {
							return (
								<AllocateTeamMembersModalV2
									{...relayProps}
									projectId={modalData.projectId}
									selectedTeamMembersIds={modalData.selectedTeamMembersIds}
									closeModal={() => {
										this.closeModal();
										if (modalData.closeModal) {
											modalData.closeModal();
										}
									}}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.TIMER_V3:
				return (
					<ForecastQueryRenderer
						key="query-render-timer-modal"
						query={timerModalV3Query}
						modal={true}
						variables={{
							personId: modalData.personId ? modalData.personId : null,
							taskId: modalData.taskId ? modalData.taskId : null,
						}}
						showLoader={false}
						render={relayProps => {
							return <TimerModalV3 {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.EXPAND_FILE:
				return <FileModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CANVAS_CREATE_NEW_MODAL:
				return <CanvasCreateNewModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CREATE_INVOICE_V2:
				return <CreateInvoiceModalV2 {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EDIT_INVOICE_V2:
				return (
					<ForecastQueryRenderer
						key="query-render-StandaloneEditInvoiceModal"
						query={standaloneEditInvoiceModalQuery}
						modal={true}
						onCloseErrorModal={this.closeModal.bind(this)}
						variables={{
							companyInvoiceId: modalData.companyInvoiceId,
							invoiceId: modalData.invoiceId,
						}}
						render={relayProps => (
							<StandaloneEditInvoiceModal
								{...relayProps}
								{...modalData}
								closeModal={this.closeModal.bind(this)}
							/>
						)}
					/>
				);
			case MODAL_TYPE.CREATE_INVOICE_V2_STANDALONE:
				return (
					<ForecastQueryRenderer
						key="query-render-StandaloneCreateInvoiceModal"
						query={standaloneCreateInvoiceModalV2Query}
						variables={{
							startYear: modalData.startDate.year(),
							startMonth: modalData.startDate.month() + 1,
							startDay: modalData.startDate.date(),
							endYear: modalData.endDate.year(),
							endMonth: modalData.endDate.month() + 1,
							endDay: modalData.endDate.date(),
							isProjectInvoicing: modalData.isProjectInvoicing,
							projectId: modalData.projectId,
						}}
						modal={true}
						customLoader={() => {
							showLoader();
							return null;
						}}
						render={relayProps => {
							hideLoader();
							return (
								<StandaloneCreateInvoiceModalV2
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);

			case MODAL_TYPE.EXPORT_INVOICE_QUICKBOOKS:
				return (
					<ExportInvoiceQuickbooksModal
						setExporting={modalData.setExporting}
						invoice={modalData.invoice}
						docNumberAvailable={modalData.docNumberAvailable}
						customers={modalData.customers}
						taxCodes={modalData.taxCodes}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.RETAINER_BULK_INVOICE_CREATE:
				return (
					<BulkInvoiceCreateModalQueryWrapper
						{...this.props}
						{...modalData}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.ACTION_COMPLETE_GO_SOMEWHERE:
				return <ActionCompleteGoToLinkModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.COPY_TIME:
				return (
					<CopyTimeModal
						closeModal={this.closeModal.bind(this)}
						timeRegistrations={modalData.timeRegistrationsToCopy}
						sourceDate={modalData.sourceDate}
						targetDate={modalData.targetDate}
						handleDateRangeChange={modalData.handleDateRangeChange}
						buttons={modalData.buttons}
						cy={modalData.cy}
					/>
				);
			case MODAL_TYPE.COPY_FROM_LAST_WEEK:
				return (
					<TimesheetsCopyLastWeekModal
						closeModal={this.closeModal.bind(this)}
						personId={modalData.personId}
						selectedDate={modalData.selectedDate}
						copyFullWeek={modalData.copyFullWeek}
						cy={modalData.cy}
					/>
				);
			case MODAL_TYPE.WARNING:
				return <WarningModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.WARNING_DATE_LOCKED:
				return <WarningModalLockedTimeRegs closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.ROLE_USED_IN_TIME_REG:
				return <WarningRoleUsedTimeRegModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.SEAT_MANAGEMENT:
				return (
					<SeatManagementModal
						closeModal={this.closeModal.bind(this)}
						showCardSection={modalData.showCardSection}
						cardLast4={modalData.cardLast4}
						cardType={modalData.cardType}
						cardExpirationDate={modalData.cardExpirationDate}
						title={modalData.title}
						isCore={modalData.isCore}
						showCollaboratorSeats={modalData.showCollaboratorSeats}
						numSeats={modalData.numSeats}
						curSeats={modalData.curSeats}
						numCollaboratorSeats={modalData.numCollaboratorSeats}
						curCollaboratorSeats={modalData.curCollaboratorSeats}
						minNumSeats={modalData.minNumSeats}
						numVirtualSeats={modalData.numVirtualSeats}
						curVirtualSeats={modalData.curVirtualSeats}
						minNumVirtualSeats={modalData.minNumVirtualSeats}
						isAdd={modalData.isAdd}
						cy={modalData.cy}
						buttons={modalData.buttons}
						hideQuestions={modalData.hideQuestions}
					/>
				);
			case MODAL_TYPE.EXTERNAL_REPORT_SHARE:
				return <ExternalShareReportModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CREATE_PROGRAM:
				return <CreateProgramModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.DELETE_PROGRAM:
				return <DeleteProgramModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.IMPORT_PROJECT_TO_PROGRAM:
				return (
					<ForecastQueryRenderer
						key="query-import-project-to-program-modal"
						query={ImportProjectToProgramModalQuery}
						modal={true}
						showLoader={false}
						render={(relayProps, retry) => (
							<ImportProjectToProgramModal
								closeModal={this.closeModal.bind(this)}
								retry={retry}
								{...relayProps}
								{...modalData}
							/>
						)}
					/>
				);
			case MODAL_TYPE.CHANGE_PROGRAM_BUDGET_TYPE:
				return <ChangeProgramBudgetTypeModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CHANGE_PROGRAM_BUDGET_VALUE:
				return <ChangeProgramBudgetValueModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.LEAVE_ALLOCATIONS_PAGE:
				return <LeaveAllocationsPageModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.PROGRAM_BUDGET_TYPE_CHANGED:
				return <ProgramBudgetTypeChangedModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.PROGRAM_ALLOCATIONS_CHANGED:
				return <ProgramAllocationsChangedModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.DETACH_PROJECT_MODAL:
				return <RemoveProjectFromProgramModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.REMOVE_PROGRAM_MEMBER_MODAL:
				return <RemoveMemberFromProgramModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.GENERIC_DELETION_WARNING:
				return <GenericDeletionWarningModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.TASK_DELETION_WARNING:
				return <TaskDeletionWarningModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.PERSON_DEACTIVATE_WARNING:
				return <PersonDeactivateWarningModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.HARVEST_TASK_SELECT:
				return <HarvestTaskSelectModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.UNIT4_ACTIVITY_SELECT:
				return <Unit4ActivitySelectModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.COST_PERIODS:
				return (
					<ForecastQueryRenderer
						key="query-render-cost-periods-modal"
						query={costPeriodsModalQuery}
						modal={true}
						showLoader={false}
						variables={{personId: modalData.personId}}
						render={relayProps => (
							<CostPeriodsModal closeModal={this.closeModal.bind(this)} {...relayProps} {...modalData} />
						)}
					/>
				);
			case MODAL_TYPE.ADD_PROJECT_DATES:
				return <AddProjectDatesModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.FIX_UNASSIGNED_TASKS:
				return <FixUnassignedTasksModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.FIX_UNESTIMATED_TASKS:
				return <FixUnestimatedTasksModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.COST_RATE:
				return <CostRateModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CHANGE_FIXED_PRICE:
				return <ChangeFixedPriceModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.UPDATE_FIXED_PRICE:
				return <UpdateFixedPriceModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CONFIRM_UPDATE_FIXED_PRICE:
				return <ConfirmUpdateFixedPriceModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CREATE_JIRA_ISSUE:
				return (
					<ForecastQueryRenderer
						key="query-render-create-jira-issue-modal"
						query={createJiraIssueModalQuery}
						variables={{
							projectId: modalData.projectId,
						}}
						modal={true}
						render={relayProps => (
							<CreateJiraIssueModal
								{...relayProps}
								viewerId={modalData.viewerId}
								closeModal={this.closeModal.bind(this)}
								{...modalData}
								projectId={modalData.projectId}
							/>
						)}
					/>
				);
			case MODAL_TYPE.CREATE_JIRA_MILESTONE:
				return (
					<ForecastQueryRenderer
						key="query-render-create-jira-milestone-modal"
						query={createJiraMilestoneModalQuery}
						variables={{
							projectId: modalData.projectId,
						}}
						modal={true}
						render={relayProps => (
							<CreateJiraMilestoneModal
								{...relayProps}
								viewerId={modalData.viewerId}
								closeModal={this.closeModal.bind(this)}
								{...modalData}
								projectId={modalData.projectId}
							/>
						)}
					/>
				);
			case MODAL_TYPE.CREATE_ADO_WORKITEM:
				return <CreateAdoWorkitemModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.ADD_EMAIL:
				return <AddEmailModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.BUDGET_FIXED_PRICE:
				return <BudgetFixedPriceModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.WARNING_RADIO_BUTTON_MODAL:
				return <WarningRadioButtonModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.RETAINER_TIME_ENTRY_CONFLICT_HANDLING:
				return <RetainerTimeEntryConflictModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.TIME_REGS_ON_PROJECT:
				return <TimeRegsOnProjectModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.TIME_REGS_OUTSIDE_PROJECT_PERIOD:
				return <TimeRegsOutsideProjectPeriod closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.TASK_OUTSIDE_PROJECT_PERIOD:
				return <TaskOutsideProjectPeriodModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.EXPENSES_OUTSIDE_PROJECT_PERIOD:
				return <ExpensesOutsideProjectPeriod closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.TASK_LOCATION:
				return (
					<ForecastQueryRenderer
						key="query-render-taskLocationModal"
						query={taskLocationModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return <TaskLocationModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.PHASE_LOCATION:
				return (
					<ForecastQueryRenderer
						key="query-render-phase-location-modal"
						query={phaseLocationModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<PhaseLocationModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />
							);
						}}
					/>
				);
			case MODAL_TYPE.PROFILE:
				return <ProfileModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CLIENT_PERMISSIONS:
				return <ClientPermissionsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.ADD_RATE_CARD:
				return (
					<ForecastQueryRenderer
						key="query-render-addRateCardModal"
						query={addRateCardModalQuery}
						modal={true}
						render={relayProps => {
							return <AddRateCardModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.TASK_MODAL:
				return (
					<div className="layer" key={modalData.taskId}>
						<TaskModalWrapper
							{...modalData}
							taskId={modalData.taskId + ''}
							closeModal={this.closeModal.bind(this)}
						/>
					</div>
				);
			case MODAL_TYPE.SYNC_JIRA_PROJECT:
				return (
					<ForecastQueryRenderer
						key="query-render-syncJiraProjectModal"
						query={syncJiraProjectModalQuery}
						variables={{
							projectId: modalData.forecastProject ? modalData.forecastProject.id : null,
						}}
						modal={true}
						render={relayProps => {
							return (
								<SyncJiraProjectModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />
							);
						}}
					/>
				);
			case MODAL_TYPE.LINK_ADO_PROJECT:
				return <LinkADOProjectModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CHANGE_ALLOCATION_TYPE:
				return <ChangeAllocationTypeModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.CHANGE_COMPANY_WEEKEND_VISIBILITY:
				return <ChangeCompanyWeekendVisibilityModalContent closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.LINK_UNIT4_PROJECT:
				return (
					<ForecastQueryRenderer
						key="query-render-linkUnit4ProjectModal"
						query={linkUnit4ProjectModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<LinkUnit4ProjectModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />
							);
						}}
					/>
				);
			case MODAL_TYPE.UNIT4_SYNC_USERS:
				return (
					<ForecastQueryRenderer
						key="query-render-unit4ImportUsers"
						query={unit4SyncUsersModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<Unit4SyncUsersModal
									company={relayProps.viewer.company}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.JIRA_SYNC_USERS:
				return (
					<ForecastQueryRenderer
						key="query-render-jiraSyncUsers"
						query={jiraSyncUsersModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<JiraSyncUsersModal
									company={relayProps.viewer.company}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			//return <JiraSyncUsersModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.BAMBOO_IMPORT_USERS:
				return (
					<ForecastQueryRenderer
						key="query-render-bamboohrImportUsers"
						query={bambooHRImportUsersModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<BambooHRImportUsersModal
									company={relayProps.viewer.company}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ADO_IMPORT_USERS:
				return (
					<ForecastQueryRenderer
						key="query-render-adoImportUsers"
						query={adoImportUsersModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<AdoImportUsersModal
									company={relayProps.viewer.company}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.INTEGRATIONS_INFO:
				return <IntegrationsInfoModal closeModal={this.closeModal.bind(this)} {...modalData} />;
			case MODAL_TYPE.ADO_LINK_USER:
				return (
					<ForecastQueryRenderer
						key="query-render-adoLinkUser"
						query={adoLinkUserModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return <AdoLinkUserModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.BAMBOO_IMPORT_TIME_OFF_TYPES:
				return <BambooHRImportTimeOffTypesModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.XERO_SYNC_CLIENTS:
				return <XeroSyncClientsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.QUICKBOOKS_SYNC_CLIENTS:
				return <QuickbooksImportCustomersModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SALESFORCE_SYNC_CLIENTS:
				return <SalesforceImportClientsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.HUBSPOT_SYNC_CLIENTS:
				return <HubspotImportClientsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.PIPEDRIVE_SYNC_CLIENTS:
				return <PipedriveImportClientsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.ECONOMIC_SYNC_CLIENTS:
				return <EconomicImportCustomersModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SAGE_INTACCT_SYNC_CLIENTS:
				return <SageIntacctImportCustomersModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SAGE_INTACCT_IMPORT_DEPARTMENTS:
				return <SageIntacctImportDepartmentsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SAGE_INTACCT_EXPORT_TIMESHEETS:
				return (
					<ForecastQueryRenderer
						key="query-render-SageIntacctExportTimesheetsModal"
						query={sageIntacctExportTimesheetsModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							startDateString: modalData.startDateMoment.format('YYYY-MM-DD'),
							endDateString: modalData.endDateMoment.format('YYYY-MM-DD'),
						}}
						render={relayProps => {
							return (
								<SageIntacctExportTimesheetsModal
									company={relayProps.viewer.company}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.SAGE_INTACCT_IMPORT_USERS:
				return (
					<ForecastQueryRenderer
						key="query-render-SageIntacctImportTeamMembersModal"
						query={sageIntacctImportTeamMembersModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<SageIntacctImportTeamMembersModal
									company={relayProps.viewer.company}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.SAGE_INTACCT_EXPORT_TASKS:
				return (
					<ForecastQueryRenderer
						key="query-render-SageIntacctExportTasksModal"
						query={sageIntacctExportTasksModalQuery}
						modal={true}
						showLoader={false}
						variables={{projectId: modalData.forecastProjectId}}
						render={relayProps => {
							return (
								<SageIntacctExportTasksModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ADD_BILL_TO_ENTITY:
				return (
					<ForecastQueryRenderer
						key="query-render-AddBillToEntityModalQuery"
						query={AddBillToEntityModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<AddBillToEntityModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.EDIT_CLIENT_DETAILS:
				return (
					<ForecastQueryRenderer
						key="query-render-EditClientDetailsModal"
						query={EditClientDetailsModalQuery}
						modal={true}
						variables={{
							clientId: modalData.clientId,
						}}
						showLoader={true}
						render={relayProps => {
							return (
								<EditClientDetailsModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ASSOCIATE_BILL_TO_ENTITY:
				return (
					<ForecastQueryRenderer
						key="query-render-AssociateBillToEntityModalQuery"
						query={AssociateBillToEntityModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							clientId: modalData.clientId,
						}}
						render={relayProps => {
							return (
								<AssociateBillToEntityModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.EXPORT_INVOICE_BILLING_OPTION:
				return (
					<ForecastQueryRenderer
						key="query-render-ExportInvoiceModalQuery"
						query={ExportInvoiceBillingOptionModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							invoiceId: modalData.invoiceId,
						}}
						render={relayProps => {
							return (
								<ExportInvoiceBillingOptionModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.MISSING_CLIENT_BILL_TO_WARNING:
				return <MissingClientBillToWarningModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.PROJECTS_BILLING_DETAILS_WARNING:
				return <ProjectsBillingDetailsWarningModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EDIT_CLIENT_NOTES:
				return <EditNotesModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SAVE_DEMO_ENVIRONMENT:
				return <SaveDemoEnvironmentModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.LOAD_DEMO_ENVIRONMENT:
				return <DemoGenerationModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CONFIRM_PASSWORD_MODAL:
				return <ConfirmPasswordModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.UPDATE_SPRINT_PERFORMANCE:
				return <UpdateSprintPerformanceModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CLOSE_SPRINT:
				return <CloseSprintModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CLOSE_PROJECT:
				return <CloseProjectModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EXPORT_INVOICE:
				return (
					<ForecastQueryRenderer
						key="query-render-ExportInvoiceModal"
						query={exportInvoiceModalQuery}
						modal={true}
						variables={{
							invoiceId: modalData.invoiceId,
							billToId: modalData.billToId,
						}}
						render={relayProps => {
							hideLoader();
							return (
								<ExportInvoiceModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.XERO_EXPORT:
				return (
					<ForecastQueryRenderer
						key="query-render-XeroExportModal"
						query={xeroExportModalQuery}
						modal={true}
						variables={{
							invoiceId: modalData.invoiceId,
						}}
						render={relayProps => {
							hideLoader();
							return (
								<XeroExportModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.QUICKBOOKS_EXPORT:
				return (
					<ForecastQueryRenderer
						key="query-render-QuickbooksExportModal"
						query={quickbooksExportModalQuery}
						modal={true}
						variables={{
							invoiceId: modalData.invoiceId,
						}}
						render={relayProps => {
							hideLoader();
							return (
								<QuickbooksExportModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ECONOMIC_EXPORT:
				return (
					<ForecastQueryRenderer
						key="query-render-EconomicExportModal"
						query={economicExportModalQuery}
						modal={true}
						variables={{
							invoiceId: modalData.invoiceId,
						}}
						render={relayProps => {
							hideLoader();
							return (
								<EconomicExportModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.SAGE_INTACCT_SYNC_PROJECT:
				return <SageIntacctSyncProjectModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SAGE_INTACCT_CREATE_NEW_PROJECT:
				return (
					<ForecastQueryRenderer
						key="query-render-SageIntacctCreateNewProjectModal"
						query={sageIntacctCreateNewProjectModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<SageIntacctCreateNewProjectModal
									company={relayProps.viewer.company}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.SAGE_INTACCT_EXPORT:
				return (
					<ForecastQueryRenderer
						key="query-render-SageIntacctExportModal"
						query={sageIntacctExportModalQuery}
						modal={true}
						variables={{
							invoiceId: modalData.invoiceId,
						}}
						render={relayProps => {
							hideLoader();
							return (
								<SageIntacctExportModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.JIRA_EDIT_EPICS:
				return (
					<ForecastQueryRenderer
						key="query-render-JiraEditEpicsModal"
						query={jiraEditEpicsModalQuery}
						modal={true}
						render={relayProps => {
							hideLoader();
							return (
								<JiraEditEpicsModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.JIRA_REMOVE_EPICS:
				return null;
			case MODAL_TYPE.ACTIVATE_TIME_APPROVAL:
				return (
					<ForecastQueryRenderer
						key="query-render-ActivateTimeApprovalModal"
						query={ActivateTimeApprovalModalQuery}
						modal={true}
						render={relayProps => {
							hideLoader();
							return (
								<ActivateTimeApprovalModal
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.SUBMIT_TIME_APPROVAL:
				return <SubmitTimeApprovalModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.UNSUBMIT_TIME_APPROVAL:
				return <UnsubmitTimeApprovalModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SUBMIT_TIME_OFF_APPROVAL:
				return <SubmitTimeOffApprovalModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.INTEGRATION_INCOMPATIBLE:
				return <IntegrationIncompatibleModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.TEXT_BUTTONS:
				return <HTMLTextButtonsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.TIME_LOCK:
				return (
					<ForecastQueryRenderer
						key="query-render-TimeLockModal"
						query={TimeLockModalQuery}
						modal={true}
						variables={{
							endDateString: '',
							fetchTimeRegs: false,
						}}
						render={relayProps => {
							hideLoader();
							return <TimeLockModal {...relayProps} {...modalData} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.NO_AVAILABLE_SEATS:
				return <NoAvailableSeatsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.JIRA_START_DATE:
				return (
					<ForecastQueryRenderer
						key="query-render-JiraStartDateModal"
						query={jiraStartDateModalQuery}
						modal={true}
						render={relayProps => {
							hideLoader();
							return (
								<JiraStartDateModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.XERO_INACCESSIBLE:
				return <XeroInaccessibleModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DOWNLOADING_QUERY_DATA:
				return <ReportedTimeCSVModalWrapper {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DOWNLOADING_QUERY_DATA_TASK:
				return <TaskReportCSVModalWrapper {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.REPORTED_TIME_DATE_CSV:
				return <ReportedTimeDateModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DOWNLOADING_CSV_DATA:
				return <CsvDownloadModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.FIXED_PRICE_LOCK:
				return <FixedPriceLockModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.FIXED_PRICE_OPEN_LOCK:
				return <FixedPriceOpenLockModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.TASK_STATUS_MICRO_ASSIST:
				return (
					<ForecastQueryRenderer
						key="query-render-task-status-micro-assist-modal"
						query={TaskStatusMicroAssistModalQuery}
						modal={true}
						variables={{
							id: modalData.companyTaskId.toString(),
						}}
						render={relayProps => {
							hideLoader();
							return (
								<TaskStatusMicroAssistModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.PREDICTED_END_DATE:
				return (
					<PredictedEndDateModalRelayWrapper {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />
				);
			case MODAL_TYPE.ALLOCATION_ASSISTANCE:
				return (
					<AllocationAssistanceModalRelayWrapper
						{...this.props}
						{...modalData}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.TEAM_FOCUS:
				return <FocusModalRelayWrapper {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.PERFORMANCE:
				return <PerformanceModalRelayWrapper {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.FORECAST_SURPLUS:
				return (
					<ForecastSurplusModalRelayWrapper {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />
				);
			case MODAL_TYPE.PROJECT_STATUS_V2:
				return <ProjectStatusModalV2 {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.PROJECT_PROGRESS:
				return (
					<ForecastThemeProvider>
						<ProgressRegistrationModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
					</ForecastThemeProvider>
				);
			case MODAL_TYPE.CONVERT_OLD_SUBTASKS:
				return (
					<ForecastQueryRenderer
						key="query-render-convert-old-subtasks-modal"
						query={convertOldSubtasksModalQuery}
						modal={true}
						variables={{projectId: modalData.projectId}}
						render={relayProps => (
							<ConvertOldSubtasksModal
								{...relayProps}
								{...this.props}
								{...modalData}
								closeModal={this.closeModal.bind(this)}
							/>
						)}
					/>
				);
			case MODAL_TYPE.DELETE_SKILL_LEVEL:
				return <DeleteSkillLevelModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CREATE_SKILL:
				return (
					<ForecastQueryRenderer
						key="query-render-create-skill-modal"
						query={createSkillModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<CreateSkillModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.CREATE_LABEL:
				return (
					<ForecastQueryRenderer
						key="query-render-create-label-modal"
						query={createLabelModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<CreateLabelModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.CREATE_LABEL_CATEGORY:
				return (
					<ForecastQueryRenderer
						key="query-render-create-label-category-modal"
						query={createLabelCategoryModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<CreateLabelCategoryModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.UPDATE_LABEL_CATEGORY:
				return (
					<ForecastQueryRenderer
						key="query-render-update-label-category-modal"
						query={updateLabelCategoryModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<UpdateLabelCategoryModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.BULK_MOVE_LABELS_TO_CATEGORY:
				return (
					<ForecastQueryRenderer
						key="query-render-move-labels-to-category-modal"
						query={moveMultipleLabelsToCategoryModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<MoveMultipleLabelsToCategoryModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.BULK_ASSIGN_SKILLS_TO_PERSONS:
				return (
					<ForecastQueryRenderer
						key="query-render-assign-skills-to-persons-modal"
						query={assignSkillsToPersonsModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<AssignSkillsToPersonsModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.BULK_MOVE_SKILLS_TO_CATEGORY:
				return (
					<ForecastQueryRenderer
						key="query-render-move-skills-to-category-modal"
						query={moveMultipleSkillsToCategoryModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<MoveMultipleSkillsToCategoryModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.UPDATE_SKILL:
				return (
					<ForecastQueryRenderer
						key="query-render-UPDATE-skill-modal"
						query={updateSkillModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<UpdateSkillModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.UPDATE_LABEL:
				return (
					<ForecastQueryRenderer
						key="query-render-update-label-modal"
						query={updateLabelModalQuery}
						variables={{labelId: modalData.labelId}}
						modal={true}
						render={relayProps => {
							return (
								<UpdateLabelModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.CREATE_SKILL_CATEGORY:
				return (
					<ForecastQueryRenderer
						key="query-render-create-skill-category-modal"
						query={createSkillCategoryModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<CreateSkillCategoryModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.MERGE_LABELS:
				return (
					<ForecastQueryRenderer
						key="query-render-merge-labels-modal"
						query={mergeLabelsModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<MergeLabelsModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.UPDATE_SKILL_CATEGORY:
				return (
					<ForecastQueryRenderer
						key="query-render-edit-skill-category-modal"
						query={updateSkillCategoryModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<UpdateSkillCategoryModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.MANAGE_SKILL_LEVELS:
				return (
					<ForecastQueryRenderer
						key="query-render-manage-skill-levels-modal"
						query={manageSkillLevelsModalQuery}
						modal={true}
						fetchPolicy={'network-only'}
						render={relayProps => {
							return (
								<ManageSkillLevelsModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ENABLE_SKILL_LEVELS_INFO_MODAL:
				return <EnableSkillLevelsInfoModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.ADD_PERSON_TO_PROJECT_MODAL:
				return <AddPersonToProjectModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CUSTOM_FIELD_DEFINITION:
				return (
					<ForecastQueryRenderer
						key="query-render-custom-field-definition-modal"
						query={CustomFieldDefinitionModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<CustomFieldDefinitionModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.DELETE_PRIORITY_LEVEL:
				return (
					<ForecastQueryRenderer
						key="query-render-delete-priority-level"
						query={deletePriorityLevelModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<DeletePriorityLevelModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.UPDATE_CONTACT_TYPE:
				return (
					<ForecastQueryRenderer
						key="query-render-update-contact-type-modal"
						query={updateContactModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<UpdateContactModal
									{...this.props}
									{...relayProps}
									{...modalData}
									company={relayProps.viewer.company}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.SETTINGS_SETUP_CONFIRM_TOGGLE_REVENUE:
				return <ToggleRevenueModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SETTINGS_SETUP_CONFIRM_TOGGLE_TIMESHEETS:
				return <ToggleTimesheetsModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SETTINGS_SETUP_CONFIRM_TOGGLE_CLIENTS:
				return <ToggleClientsModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.PROJECT_SETTINGS_EDIT_RATE_AND_COST_LITE:
				return (
					<UpdateRateAndInternalCostLiteModal
						{...this.props}
						{...modalData}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.RATE_CARD_ROLE_DEACTIVATE_WARNING:
				return (
					<RateCardRoleDeactivateWarningModal
						{...this.props}
						{...modalData}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.ROLE_DEACTIVATED_WARNING:
				return <RoleDeactivatedWarningModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CHANGE_CAP_SETTING:
				return <ChangeCapSettingModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.OWNED_PROJECTS:
				return (
					<ForecastQueryRenderer
						key="query-render-owned_projects_list"
						query={OwnedProjectsModalQuery}
						modal={true}
						render={relayProps => {
							return (
								<OwnedProjectsModal
									{...this.props}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.CREATE_TIME_REGISTRATION:
				return (
					<TimeRegistrationModalCreateQueryWrapper
						{...this.props}
						{...modalData}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.UPDATE_TIME_REGISTRATION:
				return (
					<TimeRegistrationModalUpdateQueryWrapper
						{...this.props}
						{...modalData}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.TIMER_TIME_REGISTRATION:
				return (
					<TimeRegistrationModalTimerQueryWrapper
						{...this.props}
						{...modalData}
						closeModal={this.closeModal.bind(this)}
					/>
				);
			case MODAL_TYPE.RELINK_BASELINE_SCOPING_DATES:
				return (
					<RelinkBaselineScopingDatesModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />
				);
			case MODAL_TYPE.ASSOCIATE_BILL_TO:
				return (
					<ForecastQueryRenderer
						key="query-render-AssociateBillToModalQuery"
						query={AssociateBillToModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<AssociateBillToModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.UNASSOCIATE_BILL_TO:
				return (
					<ForecastQueryRenderer
						key="query-render-UnassociateBillToModalQuery"
						query={UnassociateBillToModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<UnassociateBillToModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.REMOVE_BILL_TO:
				return (
					<ForecastQueryRenderer
						key="query-render-RemoveBillToModalQuery"
						query={RemoveBillToModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<RemoveBillToModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.REMOVE_PROJECT_BILL_TO:
				return <RemoveProjectBillToModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.REMOVE_CLIENT_BILL_TO:
				return <RemoveClientBillToModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.ADD_PROJECT_BILL_TO:
				return (
					<ForecastQueryRenderer
						key="query-render-AddProjectBillToModalQuery"
						query={AddProjectBillToModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							projectId: modalData.projectId,
						}}
						render={relayProps => {
							return (
								<AddProjectBillToModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.ASSOCIATE_CLIENT_TO_PROJECT:
				return (
					<ForecastQueryRenderer
						key="query-render-AssociateClientToProjectModalQuery"
						query={AssociateClientToProjectModalQuery}
						modal={true}
						showLoader={false}
						variables={{
							projectId: modalData.projectId,
						}}
						render={relayProps => {
							return (
								<AssociateClientToProjectModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.DISABLE_INTEGRATION_MODAL:
				return (
					<DisableIntegrationWarningModal {...this.props} {...modalData} closeModal={this.closeModal.bind(this)} />
				);
			case MODAL_TYPE.INVOICED_BILL_TO_WARNING:
				return (
					<ForecastQueryRenderer
						key="query-render-InvoicedBillToWarningModalQuery"
						query={InvoicedBillToWarningModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<InvoicedBillToWarningModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.EXPENSE_ITEM_CHANGE_PROJECT_PHASE_MODAL:
				return (
					<ForecastQueryRenderer
						key="query-render-ExpenseItemChangeProjectPhaseModalQuery"
						query={ExpenseItemChangeProjectPhaseModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<ExpenseItemChangeProjectPhaseModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.WARNING_CLIENT_CHANGE_EXPORTED_INVOICE_BILL_TO_MISMATCH:
				return <WarningClientChangeExportedInvoiceBillToMismatchModal closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.GOOGLE_CALENDAR_OAUTH_ERROR:
				return <GoogleOAuthErrorModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SHOW_HIDE_COLUMNS:
				return <ShowHideColumnsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EXPENSE_MANAGEMENT_DELETE_EXPENSES:
				return <DeleteExpensesModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EXPENSE_MANAGEMENT_DELETE_EXPENSE:
				return <DeleteExpenseModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EXPENSE_MANAGEMENT_CHANGE_MARKUP:
				return <ChangeExpensesMarkupModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EXPENSE_ITEM_CHANGE_PERSON_MODAL:
				return (
					<ForecastQueryRenderer
						key="query-render-ExpenseItemChangePersonModalQuery"
						query={ExpenseItemChangePersonModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<ExpenseItemChangePersonModal
									viewer={relayProps.viewer}
									{...relayProps}
									{...modalData}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.CONFIRMATION:
				return <ConfirmationModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.REVERT_ROLLOVER_MODAL:
				return <RevertRolloverModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DELETE_RETAINER_PERIOD_MODAL:
				return <DeleteRetainerPeriodModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.RENAME_REPORT_MODAL:
				return <RenameReportModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DELETE_REPORT_MODAL:
				return <DeleteReportModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SHARE_REPORT_MODAL:
				return <ShareReportModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.BULK_SHARE_REPORT_MODAL:
				return <BulkShareReportModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.TRANSFER_REPORT_OWNERSHIP_MODAL:
				return (
					<ForecastQueryRenderer
						key="query-render-TransferOwnershipModalQuery"
						query={TransferOwnershipModalQuery}
						variables={{permissions: reportPermissions}}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return (
								<TransferOwnershipModal
									{...modalData}
									{...relayProps}
									closeModal={this.closeModal.bind(this)}
								/>
							);
						}}
					/>
				);
			case MODAL_TYPE.PHASE_MANAGEMENT_MODAL:
				return <PhaseManagementModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DELETE_PHASE_MODAL:
				return <DeletePhaseModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CREATE_REPORT_MODAL:
				return (
					<ForecastQueryRenderer
						key="query-render-TransferOwnershipModalQuery"
						query={CreateReportModalQuery}
						modal={true}
						showLoader={false}
						render={relayProps => {
							return <CreateReportModal {...modalData} {...relayProps} closeModal={this.closeModal.bind(this)} />;
						}}
					/>
				);
			case MODAL_TYPE.SAVE_REPORT_AS_MODAL:
				return <SaveReportAsModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.SAVE_REPORT_MODAL:
				return <SaveReportModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.MANAGE_DELIVERABLES_MODAL:
				return <ManageDeliverablesModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DELIVERABLE_EDITOR_MODAL:
				return <DeliverableEditorModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.DELETE_DELIVERABLE_CONFIRMATION_MODAL:
				return <DeleteDeliverableConfirmationModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.EXIT_ACTION_BAR_MODAL:
				return <ExitActionBarModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.HIBOB_IMPORT_TEAM_MEMBERS_MODAL:
				return <HiBobImportTeamMembersModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			case MODAL_TYPE.CHOOSE_SEAT_MODAL:
				return <ChooseSeatModal {...modalData} closeModal={this.closeModal.bind(this)} />;
			default:
				return null;
		}
	}

	render() {
		if (!this.state.modalData) return null;
		return (
			<div>
				{this.state.modalData.map((data, index) => (
					<div key={index}>{this.getModalHtml(data)}</div>
				))}
			</div>
		);
	}
}

export default withRouter(GenericModalConductor);
